.navbarContainer {
  position: absolute;
  top: 0;
  z-index: 999;
  width: 100%;
  // padding: 0 150px;
  padding-top: 1em !important;
  display: flex;
  justify-content: center;

  .navbar-inner {
    width: 85%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .navbar-logo {
      // width: 3em;
      display: flex;
      gap: 4px;
      align-items: center;
      text-decoration: none !important;
      img {
        width: 10em;
      }

      p {
        font-family: "Poppins", sans-serif;
        color: white;
        font-size: 1.6rem;
        letter-spacing: 0.5px;
        font-weight: 500;
        margin: 0;
        padding: 0;
      }
    }

    .navbar {
      .collapse {
        ul {
          gap: 45px !important;
          display: flex;
          justify-content: center;
          align-items: center;
          li {
            a {
              text-decoration: none !important;
              color: white;
              font-family: "Poppins", sans-serif;
              font-size: 1em;
              position: relative;
padding-bottom: 0.2em;
              &::after {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
                width: 0%;
                content: " . ";

                color: transparent;
                background: white;
                height: 2.5px;
                transition: all 0.5s;
              }
&:hover::after {
                width: 100%;
 


               }
              
             
              &.active {
                border-bottom: 3px solid white;
               &:hover::after {
                width: 0%;
 


               }
              }
               
            }
          }
          .requestdemo {
            background-color: rgba(150, 45, 255, 1);
            color: white;
            border-radius: 7px;
            border: none;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 0.9em;
            height: 2.7em;
            width: 10em;
            font-weight: 500;
            transition: all 1s;
            &:hover {
              background-color: white;
              transform: scale(1.1);

              a {
                color: rgba(150, 45, 255, 1);
                // transform: scale(1.2);
              }
            }
          }
          .dropdown {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            display: inline-block;
            .dropdown-content {
              display: none;
              position: absolute;
              background-color: transparent;
              min-width: 170px;
              box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
              z-index: 1;
              left: 1%;
              li a {
                .dropdown-item {
                  background-color: transparent !important;
                  text-decoration: none !important;
                  .dropdown-item:hover {
                    color: red;
                  }
                }
              }
            }
          }
          .dropdown:hover .dropdown-content {
            display: block;
          }
        }
      }
    }

    .responsive-nav {
      display: none;
    }
  }
}

/* Extra Small Devices (Phones) */
@media only screen and (max-width: 576px) {
  /* Your CSS rules for extra small devices here */
  .navbarContainer {
  
    .navbar-inner {
      width: 85%;
     padding-top: 0.5em;
      .responsive-nav {
        display: block;
        width: full;
     

        a {
          svg {
            color: white;
            font-size: 1.6em;
          }
        }
        .offcanvas {
             background-color: black;
          width: 100%;
          // background-color: black;
          padding: 0.6em 1em 0 1em;
          .offcanvas-header{
              .close-btn{
              background-color: none !important;
              border: none !important;
              color: white;
            }
          }
          .offcanvas-body {
          
            ul {
              padding-top: 1.5em;
              gap: 30px;
              display: flex;
              justify-content: center;
              align-items: center;
              li {
                a {
                  text-decoration: none !important;
                  color: white;
                  font-family: "Poppins", sans-serif;
                  font-size: 1em;
                  position: relative;

                  &::after {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    margin: auto;
                    width: 0%;
                    content: ".";
                    color: transparent;
                    background: rgba(150, 45, 255, 1);
                    height: 1.3px;
                    transition: all 0.5s;
                  }

                  &:hover::after {
                    width: 100%;
                  }
                  &.active {
                    border-bottom: 1.5px solid white;
                  }
                }
              }
              .requestdemo {
                background-color: rgba(150, 45, 255, 1);
                color: white !important;
                border-radius: 7px;
                border: none;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 1em;
                height: 2.8em;
                width: 9em;
                font-weight: 500;
                transition: all 1s;
                a {
                  color: white !important;
                }
                &:hover {
                  background-color: white;
                  transform: scale(1.1);

                  a {
                    color: rgba(150, 45, 255, 1);
                    // transform: scale(1.2);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

/* Small Devices (Portrait Tablets and Small Laptops) */
@media only screen and (min-width: 577px) and (max-width: 768px) {
  .navbarContainer {
      
   
    .navbar-inner {
   padding-top: 0.5em;
      width: 90%;
      .responsive-nav {
           
        display: block;
        a {
          svg {
            color: white;
            font-size: 1.8em;
          }
        }
        .offcanvas {
             background-color: black;
          width: 50%;
          // background-color: black;
          padding: 0.6em 1em 0 1em;
          .offcanvas-header{
              .close-btn{
              background-color: none !important;
              border: none !important;
              color: white;
            }
          }
          .offcanvas-body {
          
            ul {
              padding-top: 1.5em;
              gap: 30px;
              display: flex;
              justify-content: center;
              align-items: center;
              li {
                a {
                  text-decoration: none !important;
                  color: white;
                  font-family: "Poppins", sans-serif;
                  font-size: 1em;
                  position: relative;

                  &::after {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    margin: auto;
                    width: 0%;
                    content: ".";
                    color: transparent;
                    background: rgba(150, 45, 255, 1);
                    height: 1.3px;
                    transition: all 0.5s;
                  }

                  &:hover::after {
                    width: 100%;
                  }
                  &.active {
                    border-bottom: 1.5px solid white;
                  }
                }
              }
              .requestdemo {
                background-color: rgba(150, 45, 255, 1);
                color: white !important;
                border-radius: 7px;
                border: none;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 1em;
                height: 2.8em;
                width: 9em;
                font-weight: 500;
                transition: all 1s;
                a {
                  color: white !important;
                }
                &:hover {
                  background-color: white;
                  transform: scale(1.1);

                  a {
                    color: rgba(150, 45, 255, 1);
                    // transform: scale(1.2);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

/* Medium Devices (Landscape Tablets and Larger Laptops) */
@media only screen and (min-width: 769px) and (max-width: 992px) {
}

/* Large Devices (Desktops) */
@media only screen and (min-width: 993px) and (max-width: 1200px) {
  /* Your CSS rules for large devices here */
}

/* Extra Large Devices (Large Desktops) */
@media only screen and (min-width: 1201px) and (max-width: 1440px) {
  /* Your CSS rules for extra large devices here */
}

/* XXL Devices (Extra Large Desktops) */
@media only screen and (min-width: 1441px) and (max-width: 1799px) {
  .navbarContainer {
    .navbar-inner {
      .navbar-logo {
        // width: 3em;
        display: flex;
        gap: 4px;
        align-items: center;
        text-decoration: none !important;
        img {
          width: 13em;
        }

        p {
          font-family: "Poppins", sans-serif;
          color: white;
          font-size: 1.6rem;
          letter-spacing: 0.5px;
          font-weight: 500;
          margin: 0;
          padding: 0;
        }
      }

      .navbar {
        .collapse {
          ul {
            gap: 60px !important;
            display: flex;
            justify-content: center;
            align-items: center;
            li {
              a {
                color: white;
                font-family: "Poppins", sans-serif;
                font-size: 1.2em;
              }
            }
            .requestdemo {
              background-color: rgba(150, 45, 255, 1);
              color: white;
              border-radius: 7px;
              border: none;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 0.9em;
              height: 2.7em;
              width: 10.5em;
              font-weight: 500;
              transition: all 1s;
              &:hover {
                background-color: white;
                transform: scale(1.1);
                border: none !important;

                a {
                  color: rgba(150, 45, 255, 1);
                  // transform: scale(1.2);
                }
              }
            }
            .dropdown {
              display: flex;
              align-items: center;
              justify-content: center;
              position: relative;
              display: inline-block;
              .dropdown-content {
                display: none;
                position: absolute;
                background-color: transparent;
                min-width: 170px;
                box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
                z-index: 1;
                left: 1%;
                li a {
                  .dropdown-item {
                    background-color: transparent !important;
                    text-decoration: none !important;
                    .dropdown-item:hover {
                      color: red;
                    }
                  }
                }
              }
            }
            .dropdown:hover .dropdown-content {
              display: block;
            }
          }
        }
      }

      .responsive-nav {
        display: none;
      }
    }
  }
}

/* Huge Devices (4K Monitors and Above) */
@media only screen and (min-width: 1800px) {
  .navbarContainer {
    .navbar-inner {
     width: 1200px;
    }
  }
}
