.blog-container {
  width: full;

  .blog-bg {
    width: 100%;
    height: 126vh;
    display: flex;
    justify-content: end;
    align-items: center;
    background-image: url("../assests/blog-bg.png");
    background-repeat: no-repeat;
    background-size: cover;

    flex-direction: column;
    .blog-content {
      width: 88%;
      height: 100%;
      display: flex;
      justify-content: end;

      flex-direction: column;

      h1 {
        font-family: "Italiana", sans-serif;
        font-weight: 400;
        color: rgba(245, 245, 245, 1);
        font-size: 4.88em;
        margin: 0em;
        padding: 0em;
      }

      p {
        margin: 0em;
        padding: 0em;
        color: white;
        font-family: "Lato", sans-serif;
        font-size: 1.49em;
        font-weight: 400;
      }
      .btnposition {
        width: 100%;
        display: flex;
        justify-content: start;
        
     a{
         text-decoration: none;
        margin-top: 3.5em;
        background-color: rgba(197, 52, 60, 1);
        width: 13%;
        text-align: center;
        padding: 0.5em;
        border-radius: 2em;
        color: white;
        font-weight: 500;
        font-family: "Lato", sans-serif;
     }
      }
    }
    .socialmedia {
      width: 100%;
      display: flex;

      align-items: center;
      padding: 4.5em 0 5em 0;
      gap: 0em;
      hr {
        width: 9%;
        opacity: 1;
        height: 0px;
        color: white;
      }
      .socialmediaicon {
        width: 13%;
        display: flex;
        justify-content: center;
        gap: 0.7em;
        .twitter {
          width: 14%;
        }
        .facebook {
          width: 16%;
        }
        .insta {
          width: 22%;
        }
        .linkedin {
          width: 16%;
        }
      }
      .secondhr {
        width: 78%;
      }
    }
  }
  .feature-section {
    width: 100%;
    display: flex;
    justify-content: center;
    .feature-content {
      width: 78%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      h1 {
        font-family: "Italiana", sans-serif;
        font-weight: 400;
        font-size: 2.6em;
        border-bottom: 1.6px solid #C5343C;
        width: 60%;
        text-align: center;
        padding: 3em 0em 0.3em 0;
      }
      .feature-cards {
        padding: 4em 0;
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 4.5em;
        row-gap: 11em;
        .cardd {
          padding-top: 2em;
          width: 100%;
          height: fit-content;
          padding-bottom: 1em;
          background-color: white;
          box-shadow: 0px 0px 18px 4px rgba(0, 0, 0, 0.15);
          border-radius: 0.5em;
          img {
            width: 110%;
            margin-top: -1.5em;
            margin-left: 0.8em;
          }
          .card-content {
            padding: 0 0.8em 0em 0.8em;
            h2 {
              font-family: "Italiana", sans-serif;
              font-weight: 600;
              //   margin: 0em 0 1em 0;
              font-size: 0.98em;
              height: 3.5em;
            }
            p {
              //    padding: em;
              //   margin-top: 1.2em;
              font-family: "Lato", sans-serif;
              font-weight: 300;
              font-size: 0.85em;
              line-height: 120%;
              text-align: justify;
              height: 7em;
            }
            .card-footerrr {
              //    padding: 1em;
              width: 100%;

              display: flex;
              gap: 0.5em;
              .ashokaimg {
                width: 15%;
                img {
                  width: 100%;
                  margin-top: 0em;
                  margin-left: 0em;
                }
              }

              .ashokaname {
                width: 85%;
                h6 {
                  font-family: "Lato", sans-serif;
                  //   font-weight: 300;
                  font-size: 0.86em;
                  padding: 0;
                  margin: 0;
                }
                p {
                  padding: 0;
                  margin: 0;
                  font-family: "Lato", sans-serif;
                  font-weight: 500;
                  font-size: 0.7em;
                  height: 0em;
                }
              }
            }
            .readmore {
              display: flex;
              justify-content: end;
              a {
                background-color: rgba(197, 52, 60, 1);
                width: 31%;
                text-align: center;
                padding: 0.3em;
                border-radius: 2em;
                color: white;
                font-size: 0.65em;
                font-weight: 500;
                font-family: "Lato", sans-serif;
              }
            }
          }
        }
      }
    }
  }
   .featuredcardHideSection{
    display: none;
  }

  .partner-container {
    width: 100%;
    height: 100vh;
    background-image: url("../assests/partner-blog-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    // justify-content: center;
    flex-direction: column;
    align-items: center;
    h1 {
      font-family: "Italiana", sans-serif;
      font-weight: 400;
      font-size: 2.6em;
        border-bottom: 1.6px solid #C5343C;
      width: 55%;

      text-align: center;
      padding: 0em 0em 0.3em 0;
      margin-bottom: 1.7em;
    }
  }
  
}
@media only screen and (max-width: 576px) {
  .blog-container {
  width: full;

  .blog-bg {
   
    background-image: url("../assests/blogpageBgImgMobile.png");

      width: 100%;
height: 120vh;
  
  background-size:cover;
  background-repeat: no-repeat;
background-position-y: top;
  background-position: right;
 
    flex-direction: column;
    .blog-content {
      width: 88%;
      height: 100%;
      display: flex;
      justify-content: end;

      flex-direction: column;

      h1 {
        text-align: center;
        font-family: "Italiana", sans-serif;
        font-weight: 400;
        color: rgba(245, 245, 245, 1);
        font-size: 2em;
        margin: 0em;
        padding: 0em;
      }

      p {
        text-align: center;
        margin: 0em;
        padding: 0em;
        color: white;
        font-family: "Lato", sans-serif;
        font-size: 1.2em;
        font-weight: 400;
      }
      .btnposition {
        width: 100%;
        display: flex;
        justify-content: center ;
        align-items: center;
     a{
         text-decoration: none;
        margin-top: 2em;
        background-color: rgba(197, 52, 60, 1);
        width: 50%;
        text-align: center;
        padding: 0.5em;
        border-radius: 2em;
        color: white;
        font-weight: 500;
        font-family: "Lato", sans-serif;
     }
      }
    }
    .socialmedia {
      width: 100%;
      display: flex;

      align-items: center;
      padding: 2em 0 2em 0;
      gap: 0em;
      hr {
        width: 35%;
        opacity: 1;
        height: 0px;
        color: white;
      }
      .socialmediaicon {
        width: 30%;
        display: flex;
        justify-content: center;
        gap: 0.7em;
        .twitter {
          width: 14%;
        }
        .facebook {
          width: 16%;
        }
        .insta {
          width: 22%;
        }
        .linkedin {
          width: 16%;
        }
      }
      .secondhr {
        width: 35%;
      }
    }
  }
  .feature-section {
   
    width: 100%;
    display: flex;
    justify-content: center;
    .feature-content {
      width: 90%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      h1 {
        font-family: "Italiana", sans-serif;
        font-weight: 400;
        font-size: 1.4em;
        border-bottom: 1.6px solid #C5343C;
        width: 100%;
        text-align: center;
        padding: 2em 0em 0.3em 0;
      }
      .feature-cards {
        display: none !important;
     
      
      }
    }
  }
  .featuredcardHideSection{
    display: block;
  }
  .partner-container {
    width: 100%;
    height: 100vh;
    background-image: none;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    // justify-content: center;
    flex-direction: column;
    align-items: center;
    h1 {
      font-family: "Italiana", sans-serif;
      font-weight: 400;
      font-size: 1.4em;
        border-bottom: 1.6px solid #C5343C;
      width: 90%;

      text-align: center;
      padding: 1em 0em 0.3em 0;
      margin-bottom: 0em;
    }
  }


/* Extra Small Devices (Phones) */
@media only screen and (max-width: 576px) {
}

/* Small Devices (Portrait Tablets and Small Laptops) */
@media only screen and (min-width: 577px) and (max-width: 768px) {
}

/* Medium Devices (Landscape Tablets and Larger Laptops) */
@media only screen and (min-width: 769px) and (max-width: 992px) {
  /* Your CSS rules for medium devices here */
}

/* Large Devices (Desktops) */
@media only screen and (min-width: 993px) and (max-width: 1200px) {
  /* Your CSS rules for large devices here */
}

/* Extra Large Devices (Large Desktops) */
@media only screen and (min-width: 1201px) and (max-width: 1440px) {
  /* Your CSS rules for extra large devices here */
}

/* XXL Devices (Extra Large Desktops) */
@media only screen and (min-width: 1441px) and (max-width: 1799px) {
 .slider-section-of-languages {
 
}

.slider-swiper-main-container {

}

.swiper_container {
  
}

.swiper_container .swiper-slide {

  .slide-content-bg {
   

    height: 18em;
    img {
      width: 4.7em;
    }
    p {
    
      font-size: 1.25em ;
     
      text-align: center;
    }
  }

  .partnerlogo {
   
  }
  .namedate{
   
visibility: hidden;

      font-family: "Lato", sans-serif;
      font-size: 0.85em !important ;
     
      text-align: center;
      .name{
font-weight: 700 !important;
      }
  }
}

.swiper-slide-active {

height: 19em !important;
 
  .slide-content-bg {
 
    background-color: #c5343c !important;
    img {
          width: 4em !important;
    }
    p {
     
      
   font-size: 1.12em !important;
     
    }
  }
  .partnerlogo {
    margin-top: -3em;
    // padding-top: 34%;

    width: 4.5em !important;
  }
   .namedate{
   
visibility: visible !important;

      font-family: "Lato", sans-serif;
     
     
      text-align: center;
      .name{
        margin: 0;
        padding: 0;
        font-size: 1.2em !important;
font-weight: 700 !important;
      }
  }
}
.swiper-3d .swiper-slide-shadow-left {
  background-image: none !important;
  border-radius: 30px;
}
.swiper-3d .swiper-slide-shadow-right {
  background-image: none !important;
}

.swiper-button-prev,
.swiper-button-next {
  width: 30px; /* Adjust the width as needed */
  height: 30px; /* Adjust the height as needed */
  //   background-color: #333; /* Button background color */
  position: absolute;
  top: 50%; /* Adjust the position as needed */
  transform: translateY(-50%); /* Center vertically */
  z-index: 10; /* Ensure the buttons are above the slides */
  border: 2px solid black;
  color: black;
  border-radius: 50%;
  padding: 1.5em;
}
.swiper-button-next:after {
  content: "\2192";
  font-size: 2em;
}
.swiper-button-prev:after {
  content: "\2190";
  font-size: 2em;
}

.swiper-button-prev {
  left: 1em; /* Adjust the position as needed */
}

.swiper-button-next {
  right: 1em; /* Adjust the position as needed */
}
}

/* Huge Devices (4K Monitors and Above) */
@media only screen and (min-width: 1800px) {
}

}
}
@media only screen and (min-width: 1441px) and (max-width: 1799px) {
  .blog-container {
    width: full;

    .blog-bg {
      .blog-content {
        h1 {
          font-size: 6.1em;
        }

        p {
          // margin-top: 0.3em;

          font-size: 1.9em;
        }
        .joinusbtn {
          font-size: 1.18em;
          padding: 0.5em;
        
        }
      }
      .socialmedia {
              padding: 4em 0 7em 0;

        hr {
       
        }
        .socialmediaicon {
        
          .twitter {
            width: 14%;
          }
          .facebook {
            width: 16%;
          }
          .insta {
            width: 22%;
          }
          .linkedin {
            width: 16%;
          }
        }
        .secondhr {
          width: 78%;
        }
      }
    }
    .feature-section {
  
      .feature-content {
     
        h1 {
       font-size: 3.5em;
       width: 63%;
                   border-bottom: 1.8px solid #c5343c;

        }
        .feature-cards {
        
          .cardd {
           
            img {
             
            }
            .card-content {
             
              h2 {
            
                font-size: 1.2em;
                
              }
              p {
                font-size: 1.1em;
              }
              .card-footerrr {
               
                .ashokaimg {
                  width: 20%;
                  img {
                    width: 100%;
                    margin-top: 0em;
                    margin-left: 0em;
                  }
                }

                .ashokaname {
                  width: 85%;
                  h6 {
                   
                    font-size: 1.1em;
                    
                  }
                  p {
                 
                    font-size: 0.9em;
                  
                  }
                }
              }
              .readmore {
              
                a {
                  background-color: rgba(197, 52, 60, 1);
                  width: 33%;
                  text-align: center;
                  padding: 0.4em;
                
                  font-size: 0.8em;
                 
                }
              }
            }
          }
        }
      }
    }
    .partner-container {
   
      h1 {
        font-family: "Italiana", sans-serif;
        font-weight: 400;
        font-size: 3.5em;
            border-bottom: 1.8px solid #c5343c;
        width: 47%;

        text-align: center;
        padding: 3em 0em 0.3em 0;
        margin-bottom: 1.7em;
      }
    }
  }
}
/* Small Devices (Portrait Tablets and Small Laptops) */
@media only screen and (min-width: 577px) and (max-width: 768px) {
  .blog-container {
  width: full;

  .blog-bg {
    width: 100%;
    height: 100vh;
   
    .blog-content {
        width: 90%;
      height: 100%;
      display: flex;
      justify-content: end;

      flex-direction: column;
  h1 {
    
        font-size: 3em;
      
      }

      p {
        margin: 0;
        padding: 0;
        margin-top: 0.2em;
     
        font-size: 1.2em;
        font-weight: 400;
      }
      .btnposition {
        width: 100%;
        display: flex;
        justify-content: start;
        
     a{
         text-decoration: none;
        margin-top: 1em;
        background-color: rgba(197, 52, 60, 1);
        width: 20%;
        text-align: center;
        padding: 0.5em;
        border-radius: 2em;
        color: white;
        font-weight: 500;
        font-family: "Lato", sans-serif;
     }
      }
    }
    .socialmedia {
      width: 100%;
      display: flex;

      align-items: center;
      padding: 2.5em 0 2.5em 0;
      gap: 0em;
      hr {
        width: 9%;
        opacity: 1;
        height: 0px;
        color: white;
      }
      .socialmediaicon {
        width: 13%;
        display: flex;
        justify-content: center;
        gap: 0.7em;
        .twitter {
          width: 14%;
        }
        .facebook {
          width: 16%;
        }
        .insta {
          width: 22%;
        }
        .linkedin {
          width: 16%;
        }
      }
      .secondhr {
        width: 78%;
      }
    }
  }
  .feature-section {
    width: 100%;
    display: flex;
    justify-content: center;
    .feature-content {
      width: 90%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      h1 {
        font-family: "Italiana", sans-serif;
        font-weight: 400;
         font-size: 2.2em;
        border-bottom: 1.6px solid #C5343C;
        width: 70%;
        text-align: center;
        padding: 2em 0em 0.3em 0;
      }
      .feature-cards {
        padding: 4em 0;
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 3em;
        row-gap: 6em;
        .cardd {
         
          img {
            width: 110%;
            margin-top: -3.5em;
            margin-left: 0.8em;
          }
          .card-content {
            h2 {
            
              font-size: 0.77em;
              height: 4em;
            }
            p {
            
              font-size: 0.7em;
          
              height: 6.5em;
            }
            .card-footerrr {
              //    padding: 1em;
              width: 100%;

              display: flex;
              gap: 0.5em;
              .ashokaimg {
                width: 15%;
                img {
                
                }
              }

              .ashokaname {
                width: 85%;
                h6 {
              
                  font-size: 0.7em;
                
                }
                p {
                
                  font-size: 0.6em;
                  height: 0em;
                }
              }
            }
            .readmore {
              display: flex;
              justify-content: end;
              a {
                width: 40%;
                
                font-size: 0.6em;
              
              }
            }
          }
        }
      }
    }
  }
   .featuredcardHideSection{
    display: none;
  }

  .partner-container {
  
    h1 {
      font-family: "Italiana", sans-serif;
      font-weight: 400;
      font-size: 2.2em;
     
    }
  }
  
}
}


