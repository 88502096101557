.aboutpage-container {
  width: full;

  .about-bg {
    width: 100%;
    height: 126vh;
    display: flex;
    justify-content: end;
    align-items: center;
    background-image: url("../assests/aboutheroimg.png");
    background-repeat: no-repeat;
    background-size: cover;

    flex-direction: column;
    .about-content {
      width: 88%;
      height: 100%;
      display: flex;
      justify-content: end;

      flex-direction: column;

      h1 {
        font-family: "Italiana", sans-serif;
        font-weight: 400;
        color: rgba(245, 245, 245, 1);
        font-size: 3.73em;
        margin: 0;
        padding: 0;
      }

      p {
        margin: 0;
        padding: 0;
        margin-top: 0.2em;
        color: white;
        font-family: "Lato", sans-serif;
        font-size: 1.55em;
        font-weight: 400;
        margin: 0;
        padding: 0;
      }
      .btnposition {
        width: 100%;
        display: flex;
        justify-content: start;
        
     a{
         text-decoration: none;
        margin-top: 3.5em;
        background-color: rgba(197, 52, 60, 1);
        width: 13%;
        text-align: center;
        padding: 0.5em;
        border-radius: 2em;
        color: white;
        font-weight: 500;
        font-family: "Lato", sans-serif;
     }
      }
    }
    .socialmedia {
      width: 100%;
      display: flex;

      align-items: center;
      padding: 4.5em 0 5.5em 0;
      gap: 0em;
      hr {
        color: white;
        width: 9%;
        opacity: 1;
        height: 0px;
      }
      .socialmediaicon {
        width: 13%;
        display: flex;
        justify-content: center;
        gap: 0.7em;
        .twitter {
          width: 14%;
        }
        .facebook {
          width: 16%;
        }
        .insta {
          width: 22%;
        }
        .linkedin {
          width: 16%;
        }
      }
      .secondhr {
        width: 78%;
      }
    }
  }
  .stories-section {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .storiess-content {
      margin-top: 5em;
      width: 88%;
      // height: 120vh;
      display: flex;
      justify-content: center;
      // align-items: center;
      .history-textt {
        z-index: -1;
        padding: 0em 8em 0em 3em;
        display: flex;
        flex-direction: column;
        justify-content: end;
        //  align-items: end;
        width: 60%;
      }

      .history-img {
        width: 50%;
        // margin-left: -4.2em;

        h1 {
          // background-color: white;
          color: rgba(197, 52, 60, 1);
          font-size: 5.35em;
          font-family: "Italiana", sans-serif;
          font-weight: 400;
          z-index: 2;
        }
        img {
          width: 100%;
        }
      }
    }
    .stories-content {
      margin-bottom: 9em;
      margin-top: -2em;
      width: 88%;
      // height: 120vh;
      display: flex;
      justify-content: center;
      // align-items: center;

      .history-text {
        background-color: white;
        z-index: -1;
        padding: 6em 8em 0em 2em;
        display: flex;
        flex-direction: column;
        justify-content: end;
        //  align-items: end;
        width: 65%;
        box-shadow: 10px 10px 10px 0px rgba(0, 0, 0, 0.11); /* Change the spread radius as needed */
        h1 {
          font-family: "Italiana", sans-serif;
          font-weight: 400;
          font-size: 2.6em;
          border-bottom: 1.6px solid #c5343c;
          width: 82%;
          padding-bottom: 0.12em;
        }
        p {
          margin-top: 1.7em;
          font-family: "Lato", sans-serif;
          font-size: 0.87em;
          font-weight: 400;
          color: rgba(16, 16, 16, 1);
          line-height: 190%;
          text-align: justify;
        }
      }
      .history-img {
        width: 50%;
        margin-left: -2.7em;
        z-index: -2;

        h1 {
          color: rgba(197, 52, 60, 1);
          font-size: 5.5em;
          font-family: "Italiana", sans-serif;
          font-weight: 400;
          z-index: 999;
        }
        .teamImg1 {
          width: 100%;
        }
        .teamImg2{
  display: none;

}
      }
    }
  }
  .founder-section {
    width: 100%;
    height: 80vh;
    background-image: url("../assests/founder-bg-img.png");
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    .founder-content {
      height: 100%;
      width: 88%;
      display: flex;
      justify-content: center;
      align-items: center;
      .founder-img {
        width: 50%;

        img {
          width: 85%;
        }
      }
      .founder-message {
        display: flex;
        flex-direction: column;

        color: white;
        width: 50%;
        h1 {
          text-align: end;
          font-family: "Italiana", sans-serif;
          font-weight: 400;
          border-bottom: 1.6px solid #c5343c;
          width: 100%;
          font-size: 2.62em;
          padding-bottom: 0.12em;
        }
        p {
          font-family: "Lato", sans-serif;
          font-weight: 300;
          font-size: 0.84em;
          text-align: justify;
          padding: 1em 1em 0em 3.8em;
          margin-bottom: 6em;
        }
        .social-mediass {
          display: flex;
          align-items: center;
          padding: 1em 1em 0em 3.8em;
          gap: 3.8em;
          img {
            width: 5%;
          }
          .instagram {
            width: 7%;
            margin-left: -0.32em;
          }
          p {
            font-family: "Lato", sans-serif;
            font-weight: 300;
            font-size: 1em;
            text-align: justify;
            padding: 0em 0em 0em 0em;
            margin-bottom: 0em;
          }
        }
      }
    }
  }
  .team-main-container {
    margin-top: 10em;
    height: 75vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    h1 {
      text-align: center;
      font-family: "Italiana", sans-serif;
      font-weight: 600;
      font-size: 2.6em;
      border-bottom: 1.6px solid #c5343c;
      width: 30%;
      padding-bottom: 0.12em;
    }

    .team-section {
      margin-top: 5em;
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-image: url("../assests//silaigram-teambgImg.png");
      background-repeat: no-repeat;
      background-size: cover;
      // background-position: center;

      .team-content {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .team-imgs {
          width: 88%;
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
          // justify-content: center;
          // align-items: center;
          gap: 6em;
          .wasu-img {
            margin-top: 8em;
            img {
              width: 100%;
            }
            h4 {
              margin-top: 0.6em;
              font-family: "Lato", sans-serif;
              // font-size: 1.55em;
              font-weight: 600;
              text-align: center;
            }
            p {
              font-family: "Lato", sans-serif;
              font-size: 1em;
              font-weight: 300;
              text-align: center;
            }
          }
          .sunita-img {
            margin-top: 1em;
            img {
              width: 90%;
            }
            h4 {
              margin-top: 0.6em;

              font-family: "Lato", sans-serif;
              // font-size: 1.55em;
              font-weight: 600;
              text-align: center;
            }
            p {
              font-family: "Lato", sans-serif;
              font-size: 1em;
              font-weight: 300;
              text-align: center;
            }
          }
          .yamini-img {
            margin-top: 9em;
            img {
              width: 90%;
            }
            h4 {
              margin-top: 0.6em;

              font-family: "Lato", sans-serif;
              // font-size: 1.55em;
              font-weight: 600;
              text-align: center;
            }
            p {
              font-family: "Lato", sans-serif;
              font-size: 1em;
              font-weight: 300;
              text-align: center;
            }
          }
          .nilam-img {
            margin-top: 12em;
            img {
              width: 90%;
            }
            h4 {
              margin-top: 0.6em;

              font-family: "Lato", sans-serif;
              // font-size: 1.55em;
              font-weight: 600;
              text-align: center;
            }
            p {
              font-family: "Lato", sans-serif;
              font-size: 1em;
              font-weight: 300;
              text-align: center;
            }
          }
          .charvi-img {
            margin-top: 7em;
            img {
              width: 90%;
            }
            h4 {
              margin-top: 0.6em;

              font-family: "Lato", sans-serif;
              // font-size: 1.55em;
              font-weight: 600;
              text-align: center;
            }
            p {
              font-family: "Lato", sans-serif;
              font-size: 1em;
              font-weight: 300;
              text-align: center;
            }
          }
        }
      }
    }
    .team-section-mobile{
      display: none;
    }
  
  }
  .pathway-section {
    margin-top: 11em;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    h1 {
      font-family: "Italiana", sans-serif;
      font-weight: 400;
      font-size: 2.6em;
      border-bottom: 1.6px solid #c5343c;
      width: 45%;
      padding-bottom: 0.12em;

      text-align: center;
    }
    .pathway-images {
      margin-top: 3.8em;
      width: 100%;
      display: flex;
      img {
        width: 100%;
      }
    }
  }
}


@media only screen and (max-width: 576px){
 .aboutpage-container {
  width: full;

  .about-bg {

      
      width: 100%;
height: 120vh;
  
  background-size:cover;
  background-repeat: no-repeat;
background-position-y: top;
  background-position: center;
 
    flex-direction: column;
    background-image: url("../assests/aboutpageBgImgMobile.png");
   
    .about-content {
      width: 88%;
      height: 100%;
      display: flex;
      justify-content: end;

      flex-direction: column;

      h1 {
        font-size: 2.5em;
        text-align: center;
        
      }

      p {
        margin: 0;
        padding: 0;
        text-align: center;
        margin-top: 0.2em;
        color: white;
        font-family: "Lato", sans-serif;
        font-size: 1.3em;
        font-weight: 400;
      }
      .btnposition {
        width: 100%;
        display: flex;
        justify-content: center ;
        align-items: center;
     a{
         text-decoration: none;
        margin-top: 2em;
        background-color: rgba(197, 52, 60, 1);
        width: 50%;
        text-align: center;
        padding: 0.5em;
        border-radius: 2em;
        color: white;
        font-weight: 500;
        font-family: "Lato", sans-serif;
     }
      }
    }
    .socialmedia {
      width: 100%;
      display: flex;

      align-items: center;
      padding: 2em 0 2em 0;
      gap: 0em;
      hr {
        width: 35%;
        opacity: 1;
        height: 0px;
        color: white;
      }
      .socialmediaicon {
        width: 30%;
        display: flex;
        justify-content: center;
        gap: 0.7em;
        .twitter {
          width: 14%;
        }
        .facebook {
          width: 16%;
        }
        .insta {
          width: 22%;
        }
        .linkedin {
          width: 16%;
        }
      }
      .secondhr {
        width: 35%;
      }
    }
  }
   .stories-section {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .storiess-content {
      margin-top: 2.5em;
      width: 80%;
      // height: 120vh;
      display: flex;
      justify-content: center;
      // align-items: center;
      .history-textt {
        z-index: -1;
        padding: 0em 8em 0em 3em;
        display: flex;
        flex-direction: column;
        justify-content: end;
        //  align-items: end;
        width: 60%;
        display: none;
      }

      .history-img {
        width: 100%;
        // margin-left: -4.2em;

        h1 {
          // background-color: white;
          color: rgba(197, 52, 60, 1);
          font-size: 3.5em;
          font-family: "Italiana", sans-serif;
          font-weight: 400;
          z-index: 2;
          text-align: center;
        }
        img {
          width: 100%;
        }
      }
    }
    .stories-content {
      margin-bottom: 3em;
      margin-top: -2em;
      width: 80%;
      // height: 120vh;
      display: flex;
      justify-content: center;
      align-items: center;
flex-direction: column-reverse;
      .history-text {
        background-color: white;
        z-index: -1;
        padding: 1.4em;
        display: flex;
        flex-direction: column;
        justify-content: end;
        //  align-items: end;
        width: 100%;
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.11); /* Change the spread radius as needed */
        h1 {
          font-family: "Italiana", sans-serif;
          font-weight: 400;
          font-size: 1.5em;
          text-align: center;
          border-bottom: 1.6px solid #c5343c;
          width: 100%;
          padding-bottom: 0.12em;
        }
        p {
          margin-top: 1.7em;
          font-family: "Lato", sans-serif;
          font-size: 0.87em;
          font-weight: 400;
          color: rgba(16, 16, 16, 1);
          line-height: 190%;
          text-align: justify;
          line-height: 150%;
        }
      }
      .history-img {
        width: 100%;
        margin-left: 0em;
        z-index: -2;

        h1 {
          color: rgba(197, 52, 60, 1);
          font-size: 5.5em;
          font-family: "Italiana", sans-serif;
          font-weight: 400;
          z-index: 999;
        }
        .teamImg1 {
         display: none;
        }
        .teamImg2{
          width: 100%;
  display: block;

}
      }
    }
  }
 
  .founder-section {
    width: 100%;
    height: 150vh;
    background-image: url("../assests/founder-bg-img2.png");
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    .founder-content {
      height: 100%;
      width: 80%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .founder-img {
        width: 100%;

        img {
          width: 100%;
        }
      }
      .founder-message {
        display: flex;
        flex-direction: column;

        color: white;
        width: 100%;
        h1 {
         margin: 1.2em 0;
          font-size: 1.3em;
          text-align: center;
         
        }
        p {
          font-family: "Lato", sans-serif;
          font-weight: 300;
          font-size: 0.84em;
          text-align: justify;
          padding:0em;
          margin-bottom: 2em;
        }
        .social-mediass {
          display: flex;
          align-items: center;
          flex-direction: column;
          padding: 0em;
          gap: 0.7em;
          margin-top: 1.5em;
          img {
            width: 11%;
          }
          .instagram {
            width: 14%;
            margin-left: -0.32em;
          }
          p {
            font-family: "Lato", sans-serif;
            font-weight: 300;
            font-size: 0.9em;
            text-align: left;
            padding: 0em 0em 0em 0em;
            margin-bottom: 0em;
          }
        }
      }
    }
  }
  .team-main-container {
    margin-top: 0em;
    height: 80vh;
    margin-bottom: 2em;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    h1 {
      margin: 1em 0;
      text-align: center;
      font-family: "Italiana", sans-serif;
      font-weight: 500;
      font-size: 1.4em;
      border-bottom: 1.6px solid #c5343c;
      width: 75%;
      padding-bottom: 0.12em;
    }

    .team-section {
      display: none !important;
      margin-top: 5em;
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-image: url("../assests//silaigram-teambgImg.png");
      background-repeat: no-repeat;
      background-size: cover;
      // background-position: center;

      .team-content {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .team-imgs {
          width: 88%;
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
          // justify-content: center;
          // align-items: center;
          gap: 6em;
          .wasu-img {
            margin-top: 8em;
            img {
              width: 100%;
            }
            h4 {
              margin-top: 0.6em;
              font-family: "Lato", sans-serif;
              // font-size: 1.55em;
              font-weight: 600;
              text-align: center;
            }
            p {
              font-family: "Lato", sans-serif;
              font-size: 1em;
              font-weight: 300;
              text-align: center;
            }
          }
          .sunita-img {
            margin-top: 1em;
            img {
              width: 90%;
            }
            h4 {
              margin-top: 0.6em;

              font-family: "Lato", sans-serif;
              // font-size: 1.55em;
              font-weight: 600;
              text-align: center;
            }
            p {
              font-family: "Lato", sans-serif;
              font-size: 1em;
              font-weight: 300;
              text-align: center;
            }
          }
          .yamini-img {
            margin-top: 9em;
            img {
              width: 90%;
            }
            h4 {
              margin-top: 0.6em;

              font-family: "Lato", sans-serif;
              // font-size: 1.55em;
              font-weight: 600;
              text-align: center;
            }
            p {
              font-family: "Lato", sans-serif;
              font-size: 1em;
              font-weight: 300;
              text-align: center;
            }
          }
          .nilam-img {
            margin-top: 12em;
            img {
              width: 90%;
            }
            h4 {
              margin-top: 0.6em;

              font-family: "Lato", sans-serif;
              // font-size: 1.55em;
              font-weight: 600;
              text-align: center;
            }
            p {
              font-family: "Lato", sans-serif;
              font-size: 1em;
              font-weight: 300;
              text-align: center;
            }
          }
          .charvi-img {
            margin-top: 7em;
            img {
              width: 90%;
            }
            h4 {
              margin-top: 0.6em;

              font-family: "Lato", sans-serif;
              // font-size: 1.55em;
              font-weight: 600;
              text-align: center;
            }
            p {
              font-family: "Lato", sans-serif;
              font-size: 1em;
              font-weight: 300;
              text-align: center;
            }
          }
        }
      }
    }
   .team-section-mobile{
    display: block;
    img{
      width: 100%;
    }
   }
  }
  .pathway-section {
    margin-top: 0em;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    h1 {
      font-family: "Italiana", sans-serif;
      font-weight: 400;
       font-size: 1.4em;
      border-bottom: 1.6px solid #c5343c;
      width: 90%;
      padding-bottom: 0.12em;

      text-align: center;
    }
    .pathway-images {
      margin-top: 2em;
      width: 100%;
      display: flex;
      flex-direction: column-reverse;
      img {
        width: 100%;
      }
    }
  }
}

}
@media screen and (min-width:577px) and  (max-width:768px) {
 .aboutpage-container {
  width: full;

  .about-bg {
 width: 100%;
    height: 100vh;
    .about-content {
     width: 90%;
      height: 100%;
      display: flex;
      justify-content: end;

      flex-direction: column;

      h1 {
         font-size: 3em;
      }

      p {
      margin: 0;
        padding: 0;
        margin-top: 0.2em;
     
        font-size: 1.2em;
        font-weight: 400;
      }
      .btnposition {
        width: 100%;
        display: flex;
        justify-content: start;
        
     a{
         text-decoration: none;
        margin-top: 1em;
        background-color: rgba(197, 52, 60, 1);
        width: 20%;
        text-align: center;
        padding: 0.5em;
        border-radius: 2em;
        color: white;
        font-weight: 500;
        font-family: "Lato", sans-serif;
     }
      }
    }
     .socialmedia {
      width: 100%;
      display: flex;

      align-items: center;
      padding: 2.5em 0 2.5em 0;
      gap: 0em;
      hr {
        width: 9%;
        opacity: 1;
        height: 0px;
        color: white;
      }
      .socialmediaicon {
        width: 13%;
        display: flex;
        justify-content: center;
        gap: 0.7em;
        .twitter {
          width: 14%;
        }
        .facebook {
          width: 16%;
        }
        .insta {
          width: 22%;
        }
        .linkedin {
          width: 16%;
        }
      }
      .secondhr {
        width: 78%;
      }
    }
  }
  .stories-section {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .storiess-content {
      margin-top: 2em;
      width: 88%;
      // height: 120vh;
      display: flex;
      justify-content: center;
      // align-items: center;
      .history-textt {
        z-index: -1;
        padding: 0em 0em 0em 0em;
        display: flex;
        flex-direction: column;
        justify-content: end;
        //  align-items: end;
        width: 60%;
      }

      .history-img {
        width: 50%;
        // margin-left: -4.2em;

        h1 {
         
          font-size: 4em;
         
        }
        img {
          width: 100%;
        }
      }
    }
    .stories-content {
      margin-bottom: 5em;
      margin-top: -2em;
      width: 90%;
      // height: 120vh;
      display: flex;
      justify-content: center;
      // align-items: center;

      .history-text {
        background-color: white;
        z-index: -1;
        padding: 2em 2em 0em 2em;
        display: flex;
        flex-direction: column;
        justify-content: end;
        //  align-items: end;
        width: 65%;
        box-shadow: 10px 10px 10px 0px rgba(0, 0, 0, 0.11); /* Change the spread radius as needed */
        h1 {
          font-family: "Italiana", sans-serif;
          font-weight: 400;
          font-size: 2em;
          border-bottom: 1.6px solid #c5343c;
          width: 82%;
          padding-bottom: 0.12em;
          margin-bottom: 0.6em;
        }
        p {
          margin-top: 0em;
          font-family: "Lato", sans-serif;
          font-size: 0.87em;
          font-weight: 400;
          color: rgba(16, 16, 16, 1);
          line-height: 180%;
          text-align: justify;
        }
      }
      .history-img {
        width: 50%;
        margin-left: -2.7em;
        z-index: -2;

        h1 {
          color: rgba(197, 52, 60, 1);
          font-size: 5.5em;
          font-family: "Italiana", sans-serif;
          font-weight: 400;
          z-index: 999;
        }
        .teamImg1 {
          width: 100%;
        }
        .teamImg2{
  display: none;

}
      }
    }
  }
  .founder-section {
    width: 100%;
    height: 90vh;
    background-image: url("../assests/founder-bg-img.png");
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    .founder-content {
      height: 100%;
      width: 88%;
      display: flex;
      justify-content: center;
      align-items: center;
      .founder-img {
        width: 50%;

        img {
          width: 90%;
        }
      }
      .founder-message {
        display: flex;
        flex-direction: column;

        color: white;
        width: 50%;
        h1 {
          text-align: end;
          font-family: "Italiana", sans-serif;
          font-weight: 400;
          border-bottom: 1.6px solid #c5343c;
          width: 100%;
          font-size: 1.5em;
          padding-bottom: 0.12em;
        }
        p {
          font-family: "Lato", sans-serif;
          font-weight: 300;
          font-size: 0.84em;
          text-align: justify;
          padding: 1em 1em 0em 3.8em;
          margin-bottom: 1em;
        }
        .social-mediass {
          display: flex;
          align-items: center;
          padding: 1em 1em 0em 3em;
          gap: 1em;
          img {
            width: 7%;
          }
          .instagram {
            width: 9%;
            margin-left: -0.18em;
          }
          p {
            font-family: "Lato", sans-serif;
            font-weight: 300;
            font-size: 0.85em;
            text-align: left;
            padding: 0em 0em 0em 0em;
            margin-bottom: 0em;
          }
        }
      }
    }
  }
  .team-main-container {
    margin-top: 5em;
    height: 75vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    h1 {
      text-align: center;
      font-family: "Italiana", sans-serif;
      font-weight: 600;
      font-size: 2.6em;
      border-bottom: 1.6px solid #c5343c;
      width: 55%;
      padding-bottom: 0.12em;
    }

    .team-section {
      margin-top: 5em;
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-image: url("../assests//silaigram-teambgImg.png");
      background-repeat: no-repeat;
      background-size: contain;
      // background-position: center;

      .team-content {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .team-imgs {
          width: 88%;
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
          // justify-content: center;
          // align-items: center;
          gap: 1.5em;
          .wasu-img {
            margin-top: 3em;
            img {
              width: 100%;
            }
            h4 {
              margin-top: 0.6em;
              font-family: "Lato", sans-serif;
              font-size: 0.9em;
              font-weight: 600;
              text-align: center;
            }
            p {
              font-family: "Lato", sans-serif;
              font-size: 0.8em;
              font-weight: 300;
              text-align: center;
            }
          }
          .sunita-img {
            margin-top: 0.7em;
            img {
              width: 80%;
            }
            h4 {
              margin-top: 0.6em;

              font-family: "Lato", sans-serif;
               font-size: 0.9em;
              font-weight: 600;
              text-align: center;
            }
            p {
              font-family: "Lato", sans-serif;
              font-size: 0.8em;
              font-weight: 300;
              text-align: center;
            }
          }
          .yamini-img {
            margin-top: 4.7em;
            img {
              width: 80%;
            }
            h4 {
              margin-top: 0.6em;

              font-family: "Lato", sans-serif;
             font-size: 0.9em;
              font-weight: 600;
              text-align: center;
            }
            p {
              font-family: "Lato", sans-serif;
              font-size: 0.8em;
              font-weight: 300;
              text-align: center;
            }
          }
          .nilam-img {
            margin-top: 7em;
            img {
              width: 80%;
            }
            h4 {
              margin-top: 0.6em;

              font-family: "Lato", sans-serif;
              font-size: 0.9em;
              font-weight: 600;
              text-align: center;
            }
            p {
              font-family: "Lato", sans-serif;
             font-size: 0.8em;
              font-weight: 300;
              text-align: center;
            }
          }
          .charvi-img {
            margin-top: 4em;
            img {
              width:80%;
            }
            h4 {
              margin-top: 0.6em;

              font-family: "Lato", sans-serif;
               font-size: 0.9em;
              font-weight: 600;
              text-align: center;
            }
            p {
              font-family: "Lato", sans-serif;
              font-size: 0.8em;
              font-weight: 300;
              text-align: center;
            }
          }
        }
      }
    }
  
  }
  .pathway-section {
    margin-top: 6em;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    h1 {
      font-family: "Italiana", sans-serif;
      font-weight: 400;
    font-size: 2.6em;
      border-bottom: 1.6px solid #c5343c;
      width: 70%;
      padding-bottom: 0.12em;

      text-align: center;
    }
    .pathway-images {
      margin-top: 3.8em;
      width: 100%;
      display: flex;
      img {
        width: 100%;
      }
    }
  }
}

}
@media only screen and (min-width: 1441px) and (max-width: 1799px) {
  .aboutpage-container {
    width: full;

    .about-bg {
      .about-content {
        h1 {
          font-size: 4.7em;
        }

        p {
          // margin-top: 0.3em;

          font-size: 1.9em;
        }
        .joinusbtn {
          font-size: 1.18em;
          padding: 0.5em;
        }
      }
      .socialmedia {
        padding: 4em 0 7em 0;
        hr {
          width: 9%;
          opacity: 1;
          height: 0px;
        }
        .socialmediaicon {
          width: 13%;
          display: flex;
          justify-content: center;
          gap: 0.7em;
          .twitter {
            width: 14%;
          }
          .facebook {
            width: 16%;
          }
          .insta {
            width: 22%;
          }
          .linkedin {
            width: 16%;
          }
        }
        .secondhr {
          width: 78%;
        }
      }
    }
    .stories-section {
      .storiess-content {
        .history-textt {
        }

        .history-img {
          width: 50%;
          // margin-left: -4.2em;

          h1 {
            font-size: 6.5em;
          }
          img {
            width: 100%;
          }
        }
      }
      .stories-content {
        .history-text {
          box-shadow: 10px 10px 10px 0px rgba(0, 0, 0, 0.11); /* Change the spread radius as needed */
          h1 {
            font-family: "Italiana", sans-serif;
            font-weight: 400;
            font-size: 3.5em;
            border-bottom: 1.8px solid #c5343c;
            width: 82%;
            padding-bottom: 0.12em;
          }
          p {
            font-size: 1.17em;
          }
        }
        .history-img {
          width: 50%;
          margin-left: -2.7em;
          z-index: -2;

          h1 {
            font-size: 5.5em;
          }
          img {
            width: 100%;
          }
        }
      }
    }
    .founder-section {
      margin-top: 6em;

      .founder-content {
        .founder-img {
          width: 50%;

          img {
            width: 85%;
          }
        }
        .founder-message {
          h1 {
            font-size: 3.3em;
            border-bottom: 1.8px solid #c5343c;
          }
          p {
            font-size: 1.05em;
          }
          .social-mediass {
            img {
              width: 5%;
            }
            .instagram {
              width: 7%;
              margin-left: -0.32em;
            }
            p {
              font-size: 1.3em;
            }
          }
        }
      }
    }
    .team-main-container {
      margin-top: 10em;
      h1 {
        font-size: 3.5em;
        width: 32%;
        border-bottom: 1.8px solid #c5343c;
      }

      .team-section {
        margin-top: 3em;

        .team-content {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          .team-imgs {
            width: 88%;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
            // justify-content: center;
            // align-items: center;
            gap: 6em;
            .wasu-img {
              margin-top: 10em;
              img {
                width: 90%;
              }
              h4 {
                margin-top: 0.6em;
                font-family: "Lato", sans-serif;
                // font-size: 1.55em;
                font-weight: 600;
                text-align: center;
              }
              p {
                font-family: "Lato", sans-serif;
                font-size: 1em;
                font-weight: 300;
                text-align: center;
              }
            }
            .sunita-img {
              margin-top: 1em;
              img {
                width: 80%;
              }
              h4 {
                margin-top: 0.6em;

                font-family: "Lato", sans-serif;
                // font-size: 1.55em;
                font-weight: 600;
                text-align: center;
              }
              p {
                font-family: "Lato", sans-serif;
                font-size: 1em;
                font-weight: 300;
                text-align: center;
              }
            }
            .yamini-img {
              margin-top: 10em;
              img {
                width: 80%;
              }
              h4 {
                margin-top: 0.6em;

                font-family: "Lato", sans-serif;
                // font-size: 1.55em;
                font-weight: 600;
                text-align: center;
              }
              p {
                font-family: "Lato", sans-serif;
                font-size: 1em;
                font-weight: 300;
                text-align: center;
              }
            }
            .nilam-img {
              margin-top: 14em;
              img {
                width: 80%;
              }
              h4 {
                margin-top: 0.6em;

                font-family: "Lato", sans-serif;
                // font-size: 1.55em;
                font-weight: 600;
                text-align: center;
              }
              p {
                font-family: "Lato", sans-serif;
                font-size: 1em;
                font-weight: 300;
                text-align: center;
              }
            }
            .charvi-img {
              margin-top: 9em;
              img {
                width: 80%;
              }
              h4 {
              }
              p {
              }
            }
          }
        }
      }
    }
    .pathway-section {
      h1 {
        font-size: 3.5em;
        border-bottom: 1.8px solid #c5343c;
      }
      .pathway-images {
        margin-top: 3.8em;
        width: 100%;
        display: flex;
        img {
          width: 100%;
        }
      }
    }
  }
}