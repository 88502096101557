.footer-container {
  width: 100%;
  padding: 4em 0 2.5em 0;
  background-color: #000000;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .social-media {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    .twitter {
      width: 1.5em;
    }
    .facebook {
      width: 1.8em;
    }
    .insta {
      width: 2.4em;
    }
    .linkedin {
      width: 1.7em;
    }
  }
  .footer-nav {
    padding: 1.5em 0;
    display: flex;
    justify-content: center;
    align-items: center;

    ul {
      display: flex;
      justify-content: center;
      align-items: center;
      list-style: none !important;
      gap: 0.8em;
      li {
        a {
          font-family: "Lato", sans-serif;
          font-size: 0.86em;
          border-right: 1px solid #cf0404;
          color: white;
          padding-right: 0.8em;
          text-decoration: none !important;
        }
        .joinusnavitem {
          border-right: none !important;
        }
      }
    }
  }
  h1 {
    font-family: "Italiana", sans-serif;
    font-size: 2.3em;
    font-weight: 400;
  }
  .joinusbtn {
      margin: 1.5em 0;
    background-color: rgba(197, 52, 60, 1);
    width: 9%;
    text-align: center;
    padding: 0.5em;
    border-radius: 2em;
    color: white;
    font-weight: 500;
    font-family: "Lato", sans-serif;
  }
  p {
    font-family: "Lato", sans-serif;
    font-weight: 300;
    font-size: 0.96em;
  }
  .subscribeFooterBtn {
      padding: 1.5em 0 0 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    hr {
      height: 0px;
      width: 100%;
    }
    a {
      font-family: "Lato", sans-serif;
      border: 1px solid white;
      padding: 0.5em;
      width: 25%;
      text-align: center;
      border-radius: 2em;
    }
  }
}
@media only screen and (max-width: 576px) {
  .footer-container {
    overflow: hidden;
  width: 100%;
  padding: 2.5em 0 2.5em 0;
  background-color: #000000;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .social-media {
   display: none;
    
  }
  .footer-nav {
    padding: 1.5em 0;
    display: flex;
    justify-content: center;
    align-items: center;

    ul {
     
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      list-style: none !important;
      gap: 1.2em;
      li {
        a {
       
          text-align: center;
          font-family: "Lato", sans-serif;
          font-size: 1.2em;
         border-right: 0px solid #cf0404;
padding-bottom: 0.3em;

          border-bottom: 1px solid #cf0404;
          color: white;
          padding-right: 0em;
          text-decoration: none !important;
        }
        .joinusnavitem {
          border-right: none !important;
        }
      }
    }
  }
  h1 {
    text-align: center;
    font-family: "Italiana", sans-serif;
    font-size: 1.3em;
    font-weight: 400;
    padding: 0;
    margin: 0;
  }
  .joinusbtn {
      margin: 1.4em 0;
    background-color: rgba(197, 52, 60, 1);
    width: 35%;
    text-align: center;
    padding: 0.35em;
    border-radius: 2em;
    color: white;
    font-weight: 500;
    font-family: "Lato", sans-serif;
  }
  p {
    font-family: "Lato", sans-serif;
    font-weight: 300;
    font-size: 0.92em;
  }
  .subscribeFooterBtn {
      padding: 1.5em 0 0 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    hr {
      height: 0px;
      color: white;
      width: 100%;
      opacity: 1;
    }
    a {
      font-family: "Lato", sans-serif;
      border: 1px solid white;
      padding: 0.3em;
      font-size: 0.9em;
      width: 100%;
      text-align: center;
      border-radius: 2em;
    }
  }
}
}

