.approach-container {
  width: full;

  .approach-bg {
    width: 100%;
    height: 126vh;
    display: flex;
    justify-content: end;
    align-items: center;
    background-image: url("../assests/approachPageBgImg.png");
    background-repeat: no-repeat;
    background-size: cover;

    flex-direction: column;
    .approach-content {
      width: 88%;
      height: 100%;
      display: flex;
      justify-content: end;

      flex-direction: column;

      h1 {
        font-family: "Italiana", sans-serif;
        font-weight: 400;
        color: rgba(245, 245, 245, 1);
        font-size: 3.73em;
        margin: 0;
        padding: 0;
      }

      p {
        margin: 0;
        padding: 0;
        margin-top: 0.2em;
        color: white;
        font-family: "Lato", sans-serif;
        font-size: 1.55em;
        font-weight: 400;
      }
      .btnposition {
        width: 100%;
        display: flex;
        justify-content: start;
        
     a{
         text-decoration: none;
        margin-top: 3.5em;
        background-color: rgba(197, 52, 60, 1);
        width: 13%;
        text-align: center;
        padding: 0.5em;
        border-radius: 2em;
        color: white;
        font-weight: 500;
        font-family: "Lato", sans-serif;
     }
      }
    }
    .socialmedia {
      width: 100%;
      display: flex;

      align-items: center;
      padding: 4.5em 0 5.5em 0;
      gap: 0em;
      hr {
        width: 9%;
        opacity: 1;
        height: 0px;
        color: white;
      }
      .socialmediaicon {
        width: 13%;
        display: flex;
        justify-content: center;
        gap: 0.7em;
        .twitter {
          width: 14%;
        }
        .facebook {
          width: 16%;
        }
        .insta {
          width: 22%;
        }
        .linkedin {
          width: 16%;
        }
      }
      .secondhr {
        width: 78%;
      }
    }
  }
  .model-section {
    padding: 5.5em 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .model-content {
      width: 88%;
      display: flex;
      justify-content: center;
      align-items: center;

      .model-text {
        width: 65%;
        h1 {
          font-family: "Italiana", sans-serif;
          font-weight: 400;
          font-size: 2.6em;
          border-bottom: 1.6px solid #c5343c;
          width: 33%;
        }
        .modellist {
          // padding: 0em 13em 0em 0em;
          width: 73%;

          p {
            font-family: "Lato", sans-serif;
            font-size: 0.88em;
            font-weight: 400;
            color: rgba(16, 16, 16, 1);
            line-height: 180%;
            text-align: justify;
            margin-top: 2em;
          }
          ul {
            margin: 0;
            padding: 0;
            margin-top: 2em;
            margin-left: 1.5em;
            font-family: "Lato", sans-serif;
            font-size: 0.88em;
            font-weight: 400;
            color: rgba(16, 16, 16, 1);
            line-height: 180%;
            li {
              text-align: justify;
            }
          }
        }
      }
      .model-img {
        width: 35%;

        img {
          width: 95%;
        }
      }
    }
  }
  .modelOutcomes-section {
    margin-bottom: 10em;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .modelOutcomes-content {
      width: 88%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      h1 {
        font-family: "Italiana", sans-serif;
        font-weight: 400;
        font-size: 2.6em;
        border-bottom: 1.6px solid #c5343c;
        width: 50%;
        text-align: center;
        padding-bottom: 0.3em;
      }
      .modelOutcomes {
        margin-top: 5em;

        width: 100%;
        display: flex;
        justify-content: center;
        gap: 6em;
        .modelColumn1 {
          padding-top: 9.5em;
          width: 33.3%;
          .column1text {
            padding: 7em 0em 7em 0em;
            h3 {
              font-family: "Lato", sans-serif;
              font-size: 2.22em;
              font-weight: 500;
            }
            p {
              margin-top: 1.2em;
              font-family: "Lato", sans-serif;
              font-weight: 400;
              font-size: 0.95em;
              line-height: 160%;
              text-align: justify;
            }
          }
          img {
            margin: 0em 0;
            width: 90%;
          }
        }
        .modelColumn2 {
          width: 3.1%;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .modelColumn3 {
          padding-top: 11em;
          //   padding-top: 10em;
          width: 33.3%;
          .column3text {
            padding: 0em 0;

            h3 {
              font-family: "Lato", sans-serif;
              font-size: 2.22em;
              font-weight: 400;
            }
            p {
              margin-top: 1em;
              font-family: "Lato", sans-serif;
              font-weight: 400;
              font-size: 0.95em;
              line-height: 160%;
              text-align: justify;
            }
          }
          img {
            padding: 7em 0em 7em 0em;

            width: 100%;
          }
        }
      }
    }
  }
}

/* Extra Small Devices (Phones) */
@media only screen and (max-width: 576px) {
.approach-container {
  width: full;

  .approach-bg {
    width: 100%;
height: 120vh;
  
    background-image: url("../assests/approachbgImg-mobile.png");
  background-size:cover;
  background-repeat: no-repeat;
background-position-y: top;
  background-position: right;
 
    flex-direction: column;
    .approach-content {
      width: 88%;
      height: 100%;
      display: flex;
      justify-content: end;

      flex-direction: column;

      h1 {
        font-size: 2.5em;
        text-align: center;
        
      }

      p {
        margin: 0;
        padding: 0;
        text-align: center;
        margin-top: 0.2em;
        color: white;
        font-family: "Lato", sans-serif;
        font-size: 1.3em;
        font-weight: 400;
      }
      .btnposition {
        width: 100%;
        display: flex;
        justify-content: center ;
        align-items: center;
     a{
         text-decoration: none;
        margin-top: 2em;
        background-color: rgba(197, 52, 60, 1);
        width: 50%;
        text-align: center;
        padding: 0.5em;
        border-radius: 2em;
        color: white;
        font-weight: 500;
        font-family: "Lato", sans-serif;
     }
      }
    }
    .socialmedia {
      width: 100%;
      display: flex;

      align-items: center;
      padding: 2em 0 2em 0;
      gap: 0em;
      hr {
        width: 35%;
        opacity: 1;
        height: 0px;
        color: white;
      }
      .socialmediaicon {
        width: 30%;
        display: flex;
        justify-content: center;
        gap: 0.7em;
        .twitter {
          width: 14%;
        }
        .facebook {
          width: 16%;
        }
        .insta {
          width: 22%;
        }
        .linkedin {
          width: 16%;
        }
      }
      .secondhr {
        width: 35%;
      }
    }
  }
  .model-section {
    padding: 5.5em 0 3.5em 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .model-content {
      width: 85%;
    
flex-direction: column-reverse;
      .model-text {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        h1 {
          
          font-family: "Italiana", sans-serif;
          font-weight: 400;
          font-size: 1.5em;
          border-bottom: 1.6px solid #c5343c;
          width: 50%;
        
          text-align: center;
        }
        .modellist {
          // padding: 0em 13em 0em 0em;
          width: 100%;

          p {
            font-family: "Lato", sans-serif;
            font-size: 15px;
            font-weight: 400;
            color: rgba(16, 16, 16, 1);
            line-height: 150%;
            text-align: justify;
            margin-top: 2em;
          }
          ul {
            margin: 0;
            padding: 0;
            margin-top: 2em;
            margin-left: 1.5em;
            font-family: "Lato", sans-serif;
           
          font-size: 15px;
            font-weight: 400;
            color: rgba(16, 16, 16, 1);
            line-height: 150%;
            text-align: justify;
          
          
            li {
              text-align: justify;
            }
          }
        }
      }
      .model-img {
        width: 100%;
display: flex;
justify-content: center;
        img {
          width: 75%;
        }
      }
    }
  }
  .modelOutcomes-section {
    margin-bottom: 10em;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .modelOutcomes-content {
      width: 85%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      h1 {
          font-family: "Italiana", sans-serif;
          font-weight: 400;
          font-size: 1.5em;
          border-bottom: 1.6px solid #c5343c;
          width: 90%;
        
          text-align: center;
      }
      .modelOutcomes {
        margin-top: 3em;

        width: 100%;
        display: flex;
        justify-content: center;
        gap: 1.5em;
        .modelColumn1 {
          padding-top: 6em;
          width: 40%;
          .column1text {
            padding: 3em 0em 1.5em 0em;
            h3 {
              font-family: "Lato", sans-serif;
              font-size:1em;
              font-weight: 500;
            }
            p {
              margin-top: 1.2em;
              font-family: "Lato", sans-serif;
              font-weight: 400;
              font-size: 0.65em;
              line-height: 160%;
              text-align: justify;
            }
          }
          img {
            margin: 0em 0;
            width: 100%;
          }
        }
        .modelColumn2 {
         
          width: 7%;
          img{
            width: 100%;
          }
          img {
            width: 100%;
            height: 100%;
          }
        }
        .modelColumn3 {
              padding-top: 4em;
          width: 40%;
          .column3text {
            padding: 3em 0em 1.5em 0em;

            h3 {
              font-family: "Lato", sans-serif;
              font-size: 1em;
              font-weight: 400;
            }
            p {
              margin-top: 1em;
              font-family: "Lato", sans-serif;
              font-weight: 400;
              font-size: 0.65em;
              line-height: 160%;
              text-align: justify;
            }
          }
          img {
            padding: 1em 0em 1em 0em;

            width: 100%;
          }
        }
      }
    }
  }
}
}

/* Small Devices (Portrait Tablets and Small Laptops) */
@media only screen and (min-width: 577px) and (max-width: 768px) {
 .approach-container {
  width: full;

  .approach-bg {
    width: 100%;
    height: 100vh;
  

 
    .approach-content {
      width: 90%;
      height: 100%;
      display: flex;
      justify-content: end;

      flex-direction: column;

      h1 {
    
        font-size: 3em;
      
      }

      p {
        margin: 0;
        padding: 0;
        margin-top: 0.2em;
     
        font-size: 1.2em;
        font-weight: 400;
      }
      .btnposition {
        width: 100%;
        display: flex;
        justify-content: start;
        
     a{
         text-decoration: none;
        margin-top: 1em;
        background-color: rgba(197, 52, 60, 1);
        width: 20%;
        text-align: center;
        padding: 0.5em;
        border-radius: 2em;
        color: white;
        font-weight: 500;
        font-family: "Lato", sans-serif;
     }
      }
    }
    .socialmedia {
      width: 100%;
      display: flex;

      align-items: center;
      padding: 2.5em 0 2.5em 0;
      gap: 0em;
      hr {
        width: 9%;
        opacity: 1;
        height: 0px;
        color: white;
      }
      .socialmediaicon {
        width: 13%;
        display: flex;
        justify-content: center;
        gap: 0.7em;
        .twitter {
          width: 14%;
        }
        .facebook {
          width: 16%;
        }
        .insta {
          width: 22%;
        }
        .linkedin {
          width: 16%;
        }
      }
      .secondhr {
        width: 78%;
      }
    }
  }
  .model-section {
    padding: 5.5em 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .model-content {
      width: 90%;
      display: flex;
      justify-content: center;
      align-items: center;

      .model-text {
        width: 65%;
        h1 {
          font-family: "Italiana", sans-serif;
          font-weight: 400;
          font-size: 2.2em;
          border-bottom: 1.6px solid #c5343c;
          width: 40%;
        }
        .modellist {
          // padding: 0em 13em 0em 0em;
          width: 73%;

          p {
            font-family: "Lato", sans-serif;
            font-size: 0.88em;
            font-weight: 400;
            color: rgba(16, 16, 16, 1);
            line-height: 150%;
            text-align: justify;
            margin-top: 2em;
          }
          ul {
            margin: 0;
            padding: 0;
            margin-top: 2em;
            margin-left: 1.5em;
            font-family: "Lato", sans-serif;
            font-size: 0.88em;
            font-weight: 400;
            color: rgba(16, 16, 16, 1);
            line-height: 150%;
            li {
              text-align: justify;
            }
          }
        }
      }
      .model-img {
        width: 35%;

        img {
          width: 100%;
        }
      }
    }
  }
  .modelOutcomes-section {
    margin-bottom: 10em;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .modelOutcomes-content {
      width: 90%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      h1 {
        font-family: "Italiana", sans-serif;
        font-weight: 400;
        font-size: 2em;
        border-bottom: 1.6px solid #c5343c;
        width: 50%;
        text-align: center;
        padding-bottom: 0.3em;
      }
      .modelOutcomes {
        margin-top: 5em;

        width: 100%;
        display: flex;
        justify-content: center;
        gap: 3em;
        .modelColumn1 {
          padding-top: 9.5em;
          width: 40%;
          .column1text {
            padding: 5em 0em 5em 0em;
            h3 {
              font-family: "Lato", sans-serif;
             font-size: 1.3em;
              font-weight: 500;
            }
            p {
              margin-top: 1em;
              font-family: "Lato", sans-serif;
              font-weight: 400;
              font-size: 0.85em;
              line-height: 150%;
              text-align: justify;
            }
          }
          img {
            margin: 0em 0;
            width: 100%;
          }
        }
        .modelColumn2 {
          width: 4.5%;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .modelColumn3 {
          padding-top: 11em;
          //   padding-top: 10em;
          width: 40%;
          .column3text {
            padding: 0em 0;

            h3 {
           font-family: "Lato", sans-serif;
             font-size: 1.3em;
              font-weight: 500;
            }
            p {
              margin-top: 1em;
              font-family: "Lato", sans-serif;
              font-weight: 400;
              font-size: 0.85em;
              line-height: 150%;
              text-align: justify;
            }
          }
          img {
            padding: 7em 0em 7em 0em;

            width: 100%;
          }
        }
      }
    }
  }
}
 
}

@media only screen and (min-width: 1441px) and (max-width: 1799px) {
  .approach-container {
    width: full;

    .approach-bg {
      .approach-content {
        h1 {
          font-size: 4.7em;
        }

        p {
          font-size: 1.9em;
        }
        .btnposition {
        width: 100%;
        display: flex;
        justify-content: start;
        
     a{
         
        font-size: 1.18em;
       
     }
      }
      }
      .socialmedia {
        padding: 4em 0 7em 0;

        hr {
        }
        .socialmediaicon {
          width: 13%;
          display: flex;
          justify-content: center;
          gap: 0.7em;
          .twitter {
            width: 14%;
          }
          .facebook {
            width: 16%;
          }
          .insta {
            width: 22%;
          }
          .linkedin {
            width: 16%;
          }
        }
        .secondhr {
          width: 78%;
        }
      }
    }
    .model-section {
      padding: 6.5em 0;

      .model-content {
        .model-text {
          width: 65%;
          h1 {
            font-size: 3.5em;
            border-bottom: 1.8px solid #c5343c;
          }
          .modellist {
            // padding: 0em 13em 0em 0em;
            width: 73%;

            p {
              font-size: 1.1em;
            }
            ul {
              font-size: 1.1em;
              li {
                text-align: justify;
              }
            }
          }
        }
        .model-img {
          width: 35%;

          img {
            width: 95%;
          }
        }
      }
    }
    .modelOutcomes-section {
      .modelOutcomes-content {
        h1 {
          font-size: 3.5em;
          width: 48%;
          border-bottom: 1.8px solid #c5343c;
        }
        .modelOutcomes {
          margin-top: 5em;

          width: 100%;
          display: flex;
          justify-content: center;
          gap: 6em;
          .modelColumn1 {
            padding-top: 9.5em;
            width: 33.3%;
            .column1text {
              padding: 7em 0em 7em 0em;
              h3 {
                font-size: 2.7em;
              }
              p {
                font-size: 1.23em;
              }
            }
            img {
              margin: 0em 0;
              width: 90%;
            }
          }
          .modelColumn2 {
            width: 3.1%;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .modelColumn3 {
            padding-top: 11em;
            //   padding-top: 10em;
            width: 33.3%;
            .column3text {
              padding: 0em 0;

              h3 {
                font-size: 2.7em;
              }
              p {
                font-size: 1.23em;
              }
            }
            img {
              padding: 7em 0em 7em 0em;

              width: 100%;
            }
          }
        }
      }
    }
  }
}
