.page-container{
    font-family: "lato" sans-serif;
    height: 190vh;
    .forms-background{
        height: 190vh;
        background-color: black;
    }
    .page-content{
        height: 50vh;
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin-left: 13em;
            p{
                font-size: 1.95em;
                color: white;
                font-weight: 400;
                margin: 5em 0 0em 0;
                // margin-bottom: -1em;
            }        
            h1{
                font-weight: 600;
                color: #C5343C;
                font-size: 9em;
            }
        }           
    // }  
    .forms-container{       
        display: inline-block;
        width: 170vh;
        height: 90vh;
        color: white;
        border-radius: 1em;
        font-weight: 400;        
        margin: -3em 0 0 8em;
        
        .form{
            // border: 2px solid red;
            margin: 1em 0 0 5em;
            font-size: 1.4em;
            display: inline-block;
            color: azure;
            height: 130vh;
            .forms-section{
                width: 35.5vw;
                display: inline-block;                    
            }
            ::placeholder{
                color: white;
                font-weight: 500;
                opacity: 0;
            }
            input{
                // border: 2px solid red;
                width: 80%;
                margin-left: 1em;
                box-sizing: border-box;
                background: transparent;
                border: none;
                outline: none;
                font-size: 1em;
                border-bottom: 2px solid #FFF;     
                opacity: 0.5;
                color: aliceblue;
                height: 7vh;
            }
            label{
                height: 9vh;
                border: none;
                outline: none;
                background: transparent;
                padding: 1em;
                justify-content: center;
                align-content: space-between;
            }
            .forms-section2{
                height: 50vh;
                margin-left: 0em; 
                display: block;
                justify-content:space-evenly;
                input{
                    height:8vh;
                    width: 83.4%;       
                }
                label{
                    height: 7vh;
                }
            }
            .button{
                text-decoration: none;
                display: block;
                background-color: rgba(197, 52, 60, 1);
                width: 15%;
                border-radius: 2em;
                color: white;
                font-weight: 500;
                height: 6vh;
                font-size: 1.2em;
                font-family: "Lato", sans-serif;
                border: 2px solid white;
                justify-content: center;
                margin: 9rem 0 0rem 2rem;
            }
        }
    }
}
@media only screen and (max-width:576px){
    .page-container{
        overflow: hidden;
        font-family: "lato" sans-serif;
        height: 280vh;
        width: 150vh;
        .forms-background{
            height: 280vh;
            background-color: black;
        }
        .page-content{
            height: 50vh;
            display: flex;
            justify-content: center;
            flex-direction: column;
            margin: 0 1.5em -1em 5em;
                p{
                    font-size: 1.95em;
                    color: white;
                    font-weight: 300;
                    margin-top: 5em;
                }        
                h1{
                    font-weight: 600;
                    color: #C5343C;
                    font-size: 7.3em;
                }
            }
            .changemaker-content{
                display: none;
            }            
        // }  
        .forms-container{
            // border: 2px solid green;
            display: flex;
            justify-content: center;
            align-self: start;
            // flex-direction: column;
            margin-left: 0em;
            width: 150vh;
            height: 150vh;
            color: white;
            border-radius: 1em;
            font-weight: 400;        
            margin-top: 4em;
            
            .form{
                // border: 2px solid red;
                margin: 1em 0 0 2em;
                font-size: 1.9em;
                display:flex;
                width: 150vh;
                flex-direction: column  ;
                color: azure;
                height: 130vh;
                .forms-section{
                    width: 95%;
                    display: inline-block;                    
                }
                ::placeholder{
                    color: white;
                    font-weight: 500;
                    opacity: 0;
                }
                input{
                    // border: 2px solid red;
                    width: 90%;
                    margin-left: 1em;
                    box-sizing: border-box;
                    background: transparent;
                    border: none;
                    outline: none;
                    font-size: 1em;
                    border-bottom: 2px solid #FFF;     
                    opacity: 0.5;
                    color: aliceblue;
                    height: 9vh;
                }
                label{
                    height: 9vh;
                    border: none;
                    outline: none;
                    background: transparent;
                    padding: 1em;
                    justify-content: center;
                    align-content: space-between;
                }
                .forms-section2{
                    height: 50vh;
                    margin-left: 0em; 
                    display: block;
                    justify-content:space-evenly;
                    input{
                        height:11vh;
                        width: 85%;       
                    }
                    label{
                        height: 7vh;
                    }
                }
                .button{
                    text-decoration: none;
                    display: block;
                    background-color: rgba(197, 52, 60, 1);
                    width: 25%;
                    border-radius: 2em;
                    color: white;
                    font-weight: 500;
                    height: 19vh;
                    font-size: 1.2em;
                    font-family: "Lato", sans-serif;
                    border: 2px solid white;
                    justify-content: center;
                    margin: 6em 0 0 8em;
                }
            }
        }
    }
}    