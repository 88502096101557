
.hidesection{
  display: none;
.cardd {
    width: 100%;

    box-shadow: 0px 0px 18px 4px rgba(0, 0, 0, 0.25);
    img {
    width: 100%;
    }
    .title {
    h1 {
        color: rgba(224, 21, 32, 1);
        font-family: "Italiana", sans-serif;
        font-weight: 600;
        padding-top: 0.5em;
        text-align: center;
        font-size: 1.6em;
    }
    ul {
        padding: 0em 3em 2em 3em;
        li {
        font-family: "Lato", sans-serif;
        color: rgba(16, 16, 16, 1);
        text-align: justify;
        font-size: 0.7em;
        padding: 1.2em 0 0 0;
        }
    }
    }
}
}

@media only screen and (max-width:576px) {
   
      .hidesection{
        display: flex;
        margin-top: -6.9em;
        // border: 2px solid blue;
        width: 90%;
        height: 100vh;
        padding: 0.5em;
        
        .slide{
          
          display: flex;
          flex-direction: column;
          width: 350px;
          height: 600px;
          box-shadow: 0px 0px 14px 10px rgba(0, 0, 0, 0.25);
          img{
            width: 350px;
            height: 250px;
          }
          .title{
            // border: 2px solid red;
            width: 300px;
            text-align: center;
            margin-top: 20px;
            h1{
              font-family: "Italiana", sans-serif;
              font-weight: 400;
              color:#c5343c ;
            }
            ul{
              li{
                padding: 1%;
                text-align: justify;
                font-weight: 500;
              }
            }
          }
  
        }
      }
  } 
    