.joinus-container {
  width: full;

  .joinus-bg {
    width: 100%;
    height: 126vh;
    display: flex;
    justify-content: end;
    align-items: center;
    background-image: url("../assests/joinus-bg\ \(1\).png");
    background-repeat: no-repeat;
    background-size: cover;

    flex-direction: column;
    .joinus-content {
      width: 88%;
      height: 100%;
      display: flex;
      justify-content: end;

      flex-direction: column;

      h1 {
        font-family: "Italiana", sans-serif;
        font-weight: 400;
        color: rgba(245, 245, 245, 1);
        font-size: 4.88em;
        margin: 0em;
        padding: 0em;
      }

      p {
        margin: 0em;
        padding: 0em;
        color: white;
        font-family: "Lato", sans-serif;
        font-size: 1.49em;
        font-weight: 400;
      }
      .joinusbtn {
        text-decoration: none;
        margin-top: 3em;
        background-color: rgba(197, 52, 60, 1);
        width: 12%;
        text-align: center;
        padding: 0.4em;
        border-radius: 2em;
        color: white;
        font-weight: 500;
        font-family: "Lato", sans-serif;
      }
    }
    .socialmedia {
      width: 100%;
      display: flex;

      align-items: center;
      padding: 4.5em 0 5em 0;
      gap: 0.4em;
      hr {
        width: 9%;
        opacity: 1;
        height: 0px;
        color: white;
      }
      .socialmediaicon {
        width: 13%;
        display: flex;
        justify-content: center;
        gap: 0.7em;
        .twitter {
          width: 14%;
        }
        .facebook {
          width: 16%;
        }
        .insta {
          width: 22%;
        }
        .linkedin {
          width: 16%;
        }
      }
      .secondhr {
        width: 78%;
      }
    }
  }
  .whychooseus-section {
    margin: 6em 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .whychooseus-content {
      position: relative;
      width: 88%;
      height: 100%;
      display: flex;
      justify-content: start;
      align-items: center;
      .image-section {
        width: 70%;
        z-index: -1;
        img {
          width: 100%;
        }
      }
      .text-section {
        position: absolute;
        right: 0;
        width: 38%;
        color: white;
        padding: 3em 2em 0em 2em;
        background-color: black;
        z-index: 999;
        margin-left: -3em;
        h1 {
          font-family: "Italiana", sans-serif;
          font-weight: 400;
          font-size: 2.6em;
          border-bottom: 1.6px solid #c5343c;
          padding: 0em 0 0.15em 0;
          width: 92%;
          text-align: start;
        }

        ul {
          list-style: none;
          font-family: "Lato", sans-serif;
          font-weight: 300;
          font-size: 0.86em;

          margin-left: 0em !important;
        }

        ul li::before {
          content: "\2022";
          color: #c5343c;
          font-weight: bold;
          display: inline-block;
          width: 1em;
          margin-top: 0.4em;
          margin-left: -1em;
          font-size: 1.2em;
        }
        .joinusbtnposition {
          display: flex;
          justify-content: end;
          align-items: end;

          .joinusbtn {
            text-decoration: none;
            margin: 0.6em 0em 1.6em 0em;
            background-color: rgba(197, 52, 60, 1);
            width: 36% !important;
            text-align: center;
            padding: 0.4em;
            border-radius: 2em;
            color: white;
            font-weight: 500;
            font-family: "Lato", sans-serif;
            font-size: 1em;
          }
        }
      }
    }
  }
  .volunteer-section {
    width: 100%;
    height: 65vh;
    background-color: #c5343c;
    display: flex;
    justify-content: center;
    align-items: center;
    .volunteer-content {
      width: 88%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .volunteer-img {
        margin-top: 5em;
        width: 60%;
        img {
          width: 82%;
        }
      }
      .volunteer-text {
        margin-top: 3em;
        width: 50%;
        color: white;
        h1 {
          font-family: "Italiana", sans-serif;
          font-weight: 400;
          font-size: 2.6em;
          border-bottom: 1.6px solid black;
          padding: 0em 0 0.15em 0;
          width: 71%;
          padding-bottom: 0.15em;
          text-align: start;
          margin-bottom: 0.45em;
        }
        p {
          font-family: "Lato", sans-serif;
          line-height: 160%;
          font-size: 0.9em;
          width: 60%;
          margin: 0;
          padding: 0;
        }
        .volunteernowbtn {
          margin-top: 1.5em;
          background-color: white;
          width: 30%;
          text-align: center;
          padding: 0.4em;
          border-radius: 2em;
          color: rgba(197, 52, 60, 1);
          font-weight: 500;
          font-family: "Lato", sans-serif;
        }
      }
    }
  }
  .partner-section {
    margin: 7.5em 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .partner-content {
      width: 75%;
      display: flex;
      justify-content: center;
      .partner-img {
        width: 45%;
        img {
          width: 84%;
        }
      }
      .partner-text {
        margin-top: 2em;
        width: 55%;
        display: flex;
        justify-content: end;
        .titleDescription {
          display: flex;
          // align-items: end;
          justify-content: start;
          flex-direction: column;
          width: 80%;
          h1 {
            font-family: "Italiana", sans-serif;
            font-weight: 400;
            font-size: 2.6em;
            margin-bottom: 0.7em;
            border-bottom: 1.6px solid #c5343c;
            padding: 0em 0 0.15em 0;
            // border-width: 1em;
            width: 86%;
            p {
              margin-top: 1.2em;
              font-family: "Lato", sans-serif;
              font-weight: 400;
              font-size: 0.95em;
              line-height: 160%;
              text-align: justify;
            }
          }
          p {
            font-family: "Lato", sans-serif;
            font-size: 0.88em;
            line-height: 1.4em;
            text-align: justify;
            color: rgba(16, 16, 16, 1);
            font-weight: 400;
            line-height: 150%;
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 1441px) and (max-width: 1799px) {
  .joinus-container {
    .joinus-bg {
      .joinus-content {
        h1 {
          font-size: 6.4em;
        }

        p {
          // margin-top: 0.3em;

          font-size: 1.9em;
        }
        .joinusbtn {
          font-size: 1.18em;
          padding: 0.5em;
        }
      }
      .socialmedia {
        padding: 4em 0 7em 0;
        hr {
        }
        .socialmediaicon {
          width: 13%;
          display: flex;
          justify-content: center;
          gap: 0.7em;
          .twitter {
            width: 14%;
          }
          .facebook {
            width: 16%;
          }
          .insta {
            width: 22%;
          }
          .linkedin {
            width: 16%;
          }
        }
        .secondhr {
          width: 78%;
        }
      }
    }
    .whychooseus-section {
      .whychooseus-content {
        .image-section {
          width: 70%;
          z-index: -1;
          img {
            width: 100%;
          }
        }
        .text-section {
          h1 {
            font-size: 3.5em;
            border-bottom: 1.8px solid #c5343c;
          }

          ul {
            font-size: 1.1em;

            margin-left: 0em !important;
          }

          ul li::before {
          }
          .joinusbtnposition {
            display: flex;
            justify-content: end;
            align-items: end;

            .joinusbtn {
              padding: 0.4em;
              font-size: 1.3em;
            }
          }
        }
      }
    }
    .volunteer-section {
      .volunteer-content {
        .volunteer-img {
          margin-top: 5em;
          width: 60%;
          img {
            width: 82%;
          }
        }
        .volunteer-text {
          margin-top: 3em;
          width: 50%;
          color: white;
          h1 {
            width: 77%;
            border-bottom: 1.8px solid black;

            font-size: 3.5em;
          }
          p {
            font-size: 1.1em;
          }
          .volunteernowbtn {
            padding: 0.4em;
            font-size: 1.2em;
          }
        }
      }
    }
    .partner-section {
      .partner-content {
        .partner-img {
          width: 45%;
          img {
            width: 84%;
          }
        }
        .partner-text {
          .titleDescription {
            h1 {
              border-bottom: 1.8px solid #c5343c;

              font-size: 3.5em;
              width: 85%;
            }
            p {
              font-size: 1.1em;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 576px){
  .joinus-container {
    width: full;
    overflow: hidden;
    .joinus-bg {
      width: 100%;
      background-image: url(../assests/joinus-bg_resp.png);
      height: 140vh;
      .joinus-content {
        display: flex;
        justify-content:baseline ;
        align-items: center;
        margin-top: 35rem;
        
        h1 {
          font-size: 4.4em;
        }

        p {
          // margin-top: 0.3em;

          font-size: 1.5em;
        }
        .joinusbtn {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 20px;
          margin-top: 1em;
          height: 50px;
          width: 35%;
        }
      }
      .socialmedia {
        padding: 2em 0 7em 0;
        hr {
          width: 43%;
        }
        .socialmediaicon {
          width: 45%;
          display: flex;
          justify-content: center;
          gap: 0.7em;
          .twitter {
            width: 14%;
          }
          .facebook {
            width: 16%;
          }
          .insta {
            width: 22%;
          }
          .linkedin {
            width: 16%;
          }
        }
        .secondhr {
          width: 43%;
        }
      }
    }
    .whychooseus-section {
      height: 160vh;
      width: 100%;
      // border: 2px solid red;

      .whychooseus-content {
        // border: 2px solid green;    
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 30em;
        width: 140vh;
        .imag{
          // background-image: url(../assests/chooseusimg_resp.png);
          background-repeat: no-repeat;
        }
        .image-section {
          
          // border: 2px solid green;
          background-repeat: no-repeat;
          // display:grid;  
          // height: 10vh;

          width: 100%;
          z-index: 1;
          .whyus_desktop{
            display: none;
          }
          .whyus_resp{
            margin-top: -75em;
            width: 90%;
            margin-left: 1em;
            
            img {
              // display: none;
              // border: 2px solid red;
              // height: 130vh;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 80%;
              height: 500px;
              margin: auto;
              // margin-top: -48em;
            }
          }          
        }
        .textsection{

        }
        .text-section {
          // border: 2px solid red;
          margin-right: 1.7em;
          margin-top: -12em;
          width: 85%;
          // height: 110vh;
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          // margin-right: 6.5em;
          // flex-direction: column;

          // position: relative;
          h1 {
            font-size: 1.8em;
            // margin-top: -3em;
            border-bottom: 1.8px solid #c5343c;
            // width: 50vh;
            // display: inline-block;
          }

          ul {
            // display: inline-block;
            font-size: 1em;

            margin-left: 0em !important;
          }

          ul li::before {
          }
          .joinusbtnposition {
            display: flex;
            justify-content: center;
            width: 200px;
            margin-top: 2em;
            .joinusbtn {

              padding: 0.4em;
              font-size: 1em;
              margin-top: 1em;
            }
          }
        }
      }
    }
    .volunteer-section {
      height: 120vh;
      .volunteer-content {
        display: flex;
        flex-direction: column;
        height: 100%;
        .vol_img_resp{
          // background-repeat: no-repeat;
          display: flex;
          position: absolute;
          // border: 2px solid black;
          margin-left: 2em;
          border: 2px solid black;
        }
        .volunteer-img {   
                 
          width: 80%;

          img {
            // border: 2px solid black;
            overflow: hidden;
            display: block;
            margin-left: -1em;
            position: absolute;
            width: 80%;
            height: 60%;
            margin-top: -13em;
            // display: flex;
            // justify-content: start;
            // align-items: center;
          }
        }
        .volunteer-text {
          width: 90%;
          color: white;
          text-align:justify;
          margin-top: 12em;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          h1 {
            width: 100%;
            display: flex;
            border-bottom: 1.8px solid black;
            // align-items: center;
            justify-content: center;
            // align-content: center;
            font-size: 2em;
          }
          p {
            // border: 2px solid green;
            width: 100%;
            font-size: 1.2em;
            text-align: center;
            
          }
          .volunteernowbtn {
            padding: 0.4em;
            font-size: 1.2em;
            justify-content: center;
            width: 200px;
            height: 40px;
          }
        }
        .vul_resp{
          // display: none;
          // background-image:  url(../assests/aboutus_sec3_resp.png);
          
        }
      }
    }
    .partner-section {
      height: 110vh;
      width: 100%;
      .partner-content {
        // border: 2px solid lightcoral;
        flex-direction: column;
        // height: 110vh;
        width: 90vh;
        .partner-img {
          width: 100%;
          height: 80vh;
          justify-content:center;
          align-items: center;
          align-content: center;
          margin: auto;
          img {
            width: 84%;
            margin: 15em 2em 0em 2em;
          }
        }
        .partner-text {
          // border: 2px solid greenyellow;
          justify-content: space-evenly;
          width: auto;
          height: 120vh;
          .titleDescription {
            align-items: center;
            h1 {
              display: flex;
              border: 2px solid grey;
              justify-content: center;
              border-bottom: 1.8px solid #c5343c;
              align-items: center ;
              font-size: 2em;
              width: 85%;
            }
            p {
              line-height:2em;
              font-size: 1.3em;
            }
          .volunteernowbtn {
            padding: 0.4em;
            font-size: 1.5em;
            width: 40%;
            justify-content: center;
          }
          }
        }
      }
    }
  }
}
@media screen and (min-width:577px) and (max-width:768px){
  
}