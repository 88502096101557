.homepage-container {
  width: full;

    .about-bg {
    width: 100%;
    height: 126vh;
    display: flex;
    justify-content: end;
    align-items: center;
    background-image: url("../assests/aboutheroimg.png");
    background-repeat: no-repeat;
    background-size: cover;

    flex-direction: column;
    .about-content {
      width: 88%;
      height: 100%;
      display: flex;
      justify-content: end;

      flex-direction: column;

 img {
        width: 27.5%;
      }
      p {
        margin: 0;
        padding: 0;
        margin-top: 0.2em;
        color: white;
        font-family: "Lato", sans-serif;
        font-size: 1.55em;
        font-weight: 400;
        margin: 0;
        padding: 0;
      }
      .btnposition {
        width: 100%;
        display: flex;
        justify-content: start;
        
     a{
         text-decoration: none;
        margin-top: 3.5em;
        background-color: rgba(197, 52, 60, 1);
        width: 13%;
        text-align: center;
        padding: 0.5em;
        border-radius: 2em;
        color: white;
        font-weight: 500;
        font-family: "Lato", sans-serif;
     }
      }
    }
    .socialmedia {
      width: 100%;
      display: flex;

      align-items: center;
      padding: 4.5em 0 5.5em 0;
      gap: 0em;
      hr {
        color: white;
        width: 9%;
        opacity: 1;
        height: 0px;
      }
      .socialmediaicon {
        width: 13%;
        display: flex;
        justify-content: center;
        gap: 0.7em;
        .twitter {
          width: 14%;
        }
        .facebook {
          width: 16%;
        }
        .insta {
          width: 22%;
        }
        .linkedin {
          width: 16%;
        }
      }
      .secondhr {
        width: 78%;
      }
    }
  }
 
  .who-we-are-section {
    margin-top: 5em;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .who-we-are-content {
      width: 88%;
      display: flex;
      justify-content: center;
      .who-we-are-img {
        width: 45%;
        img {
          width: 84%;
        }
      }
      .who-we-are-text {
        width: 55%;
        display: flex;
        justify-content: end;
        .titleDescription {
          display: flex;
          // align-items: end;
          justify-content: end;
          flex-direction: column;
          width: 80%;
          h1 {
            font-family: "Italiana", sans-serif;
            font-weight: 400;
            font-size: 2.6em;
            margin-bottom: 0.7em;
            border-bottom: 1.6px solid #c5343c;
            // border-width: 1em;
            width: 70%;
          }
          p {
            font-family: "Lato", sans-serif;
            font-size: 1.08em;
            line-height: 1.4em;
            text-align: justify;
            color: rgba(16, 16, 16, 1);
            font-weight: 400;
            line-height: 150%;
          }
        }
      }
    }
  }
  .opportunities-section {
    margin-top: 8em;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .opportunities-content {
      padding-top: 3em;
      width: 100%;
      height: 55vh;
      background-color: rgba(0, 0, 0, 1);
      display: flex;
      //   justify-content: center;
      flex-direction: column;
      align-items: center;
      h1 {
        color: rgba(255, 255, 255, 1);
        font-family: "Italiana", sans-serif;
        font-size: 2.6em;
        font-weight: 400;
        border-bottom: 1.6px solid #c5343c;
        width: 14em;
        text-align: center;
      }
    }
    .opportunities-cards {
      margin-top: -9.94em;
      width: 85%;
      display: flex;
      justify-content: center;
      align-items: center;
      // border: 2px solid;
      .cardssss{
        margin: auto;
        // border: 2px solid red;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .cards {
        // border: 2px solid red;
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 5em;
        .cardd {
          width: 100%;
          box-shadow: 0px 0px 18px 4px rgba(0, 0, 0, 0.25);
          img {
            width: 100%;
          }
          .title {
            h1 {
              color: rgba(224, 21, 32, 1);
              font-family: "Italiana", sans-serif;
              font-weight: 600;
              padding-top: 0.5em;
              text-align: center;
              font-size: 1.6em;
            }
            ul {
              padding: 0em 3em 2em 3em;
              li {
                font-family: "Lato", sans-serif;
                color: rgba(16, 16, 16, 1);
                text-align: justify;
                font-size: 0.7em;
                padding: 1.2em 0 0 0;
              }
            }
          }
        }
      }
    }
  }
  .partner-section {
    margin-top: 8em;
    width: 100%;
    // height: 90vh;
    background-image: url("../assests//partner-bgImg.png");
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    padding: 6em 0;
    .partner-container {
      width: 88%;
      display: flex;
      justify-content: center;

      .partner-content {
        width: 100%;
        display: flex;
        justify-content: center;

        .partner-text {
          height: 100%;
          width: 70%;
          color: rgba(255, 255, 255, 1);
          border: 1px solid rgba(255, 255, 255, 1);
          padding: 1.8em 0 2.5em 1.8em;
          h1 {
            // width: 50%;
            font-family: "Italiana", sans-serif;
            font-size: 2.6em;
            font-weight: 400;
            border-bottom: 1.6px solid #c5343c;
            width: 8em;
            margin-bottom: 0.6em;
          }
          p {
            padding: 0;
            margin: 0;
            width: 84%;
            text-align: justify;
            font-size: 0.87em;
            font-family: "Lato", sans-serif;
            font-weight: 500;
            line-height: 160%;
          }
          .joinusbtnn {
            margin-top: 2em;
            background-color: rgba(197, 52, 60, 1);
            width: 20%;
            text-align: center;
            padding: 0.5em;
            border-radius: 2em;
            color: white;
            font-weight: 500;
            font-family: "Lato", sans-serif;
            // margin-bottom: 2em;
          }
        }
        .partner-img {
          margin-top: 1.5em;
          width: 45%;
          margin-left: -1.7em;
          img {
            width: 100%;
            // height: 100%;
          }
        }
      }
    }
  }
  .media {
    width: 100%;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .media-content {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      img {
        width: 100%;
      }
      .media-imgtitle {
        h1 {
          font-family: "Italiana", sans-serif;
          font-size: 2.6em;
          text-align: center;
          border-bottom: 1.6px solid #c5343c;
          font-weight: 400;
        }
        .media-img {
          margin-top: 3em;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 0.6em;
         img{
          width: 25%;
          height: 25%;
         }
         .ikeaImg{
          width: 80%;
         }
        
        }
      }
    }
  }
  .mediares{
    display: none;
  }
}
@media only screen and (max-width: 576px){
  .homepage-container {
    overflow: hidden;
    width: 100%;
    // border: 2px solid red;
      .about-bg {

      
      width: 100%;
height: 120vh;
  
  background-size:cover;
  background-repeat: no-repeat;
background-position-y: top;
  background-position: center;
 
    flex-direction: column;
      background-image: url("../assests/silaigramhero_resp.png");
   
    .about-content {
      width: 88%;
      height: 100%;
      display: flex;
      justify-content: end;

      flex-direction: column;
.silaigramhomeLogo{
 display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          img {
    width: 70%;
        }
}
       
      p {
        margin: 0;
        padding: 0;
        text-align: center;
        margin-top: 0.2em;
        color: white;
        font-family: "Lato", sans-serif;
        font-size: 1.3em;
        font-weight: 400;
      }
      .btnposition {
        width: 100%;
        display: flex;
        justify-content: center ;
        align-items: center;
     a{
         text-decoration: none;
        margin-top: 2em;
        background-color: rgba(197, 52, 60, 1);
        width: 50%;
        text-align: center;
        padding: 0.5em;
        border-radius: 2em;
        color: white;
        font-weight: 500;
        font-family: "Lato", sans-serif;
     }
      }
    }
    .socialmedia {
      width: 100%;
      display: flex;

      align-items: center;
      padding: 2em 0 2em 0;
      gap: 0em;
      hr {
        width: 35%;
        opacity: 1;
        height: 0px;
        color: white;
      }
      .socialmediaicon {
        width: 30%;
        display: flex;
        justify-content: center;
        gap: 0.7em;
        .twitter {
          width: 14%;
        }
        .facebook {
          width: 16%;
        }
        .insta {
          width: 22%;
        }
        .linkedin {
          width: 16%;
        }
      }
      .secondhr {
        width: 35%;
      }
    }
  }
   
    .who-we-are-section {
      margin-top: 0em;
      width: 100%;
      justify-content: center;
      display: flex;
      .who-we-are-content {
        display:flex ;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        width: 100%;
    
        .who-we-are-img {
          margin: 2em 0 1em 0;
          width: 80%;
          display: flex;
          justify-content: center;
         
        
          img {
            width: 100%;
          }
        }
          .who-we-are-text {
            padding-top: 1.8em;
            justify-content: center;
            width:fit-content;
            float:left;
            .titleDescription {
              align-content: center;
              display: flex;
              align-items: center;
              align-content: center;
              h1 {
                border-bottom: 1.8px solid #c5343c;
                font-family: "Italiana", sans-serif;
                font-weight: 500;
                font-size: 2em;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
              }
              p {
                font-family: "Lato", sans-serif;
                font-size: 0.9em;
              }
            }
        }
      }
    }
    .opportunities-section {
      margin-top: 2em;
      width: 100%;
      // border: 2px solid red;

      .opportunities-content {
        padding-top: 5em;
        width: 100%;
        height: 65vh;
        background-color: rgba(0, 0, 0, 1);
        display: flex;
        //   justify-content: center;
        flex-direction: column;
        align-items: center;
        // border: 2px solid red;

        h1 {
          font-size: 1.4em;
          border-bottom: 1.8px solid #c5343c;
        }
      }
      .opportunities-cards {
        // margin-left: 20em;
        // width: 0%;
        // border: 2px solid red;
        display: flex;
        justify-content: center;
        align-items: center;
        .cardssss{
          // border: 2px solid red;
          // margin-left: 12em;
          width: 110%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .cards{
          // border: 2px solid red;
          display: none;
        }
        
      }
    }
    .partner-section {
       background-image: url("../assests/partnersectionResImg.svg");
    background-repeat: no-repeat;
    background-size: cover;
    padding: 8em 0;
      .partner-container {
        height: 100vh;
        // margin-top: 2em;
        padding: 4%;
        .partner-content {   
          // margin-top: em;
          // border: 2px solid red;
          display: flex;
          flex-direction: column-reverse;
          align-items: center;
          justify-content: center;
          .partner-text {

            // padding: 5%;
            // border: 2px solid red;
            position: absolute  ;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 85%;
            // height: 110vh;
            height: fit-content;
            // margin-top: 5em ;
            padding: 1em;
            h1 {
              font-size: 1.7em;
              margin-top: 3em;
              // border: 2px solid red;
              border-bottom: 1.9px solid #c5343c;
            }
            p {
              width: 100%;
              // padding: 1%;
              font-size: 0.9em;
              margin-right: 0em;
              // line-height: 45px;
              // text-align: justify;
              // width: fit-content;
            }
            .joinusbtnn {
              display: flex;
              align-items: center;
              justify-content: center;
              margin-top: 1.5em;
              background-color: rgba(197, 52, 60, 1);
              width: 140px;
              height: 40px;
              text-align: center;
              // padding: 0.5em;
              border-radius: 2em;
              color: white;
              font-weight: 500;
              font-size: 16px;
              font-family: "Lato", sans-serif;
              // margin-bottom: 2em;
            }
          
            
          }
          .partner-img {
            // position: absolute;
            display: flex;
            align-items: center;
            margin-top: -36em;
            width: 80%;
            z-index: 5;
            margin-left: 1em;
            
          }
        }
      }
    }
  .media {
    display: none !important;
    
  }
  .mediares{
    display: flex;
    height: 70vh;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 2em 0 1em 0;
    width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
    background-image: url("../assests/mediabgImgres.png");
     h1 {
      width: 50%;
          font-family: "Italiana", sans-serif;
          font-size: 1.6em;
          text-align: center;
          border-bottom: 1.6px solid #c5343c;
          font-weight: 400;
        }
        .media-imgres {
        margin-top: 2em;
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          img {
            width: 36%;
          }
        }
  }
  
  }

}   


@media screen and (min-width:577px) and (max-width:768px) {
  .homepage-container {
    overflow: hidden;
    width:100vw;
    .about-bg {
 width: 100%;
    height: 100vh;
    .about-content {
     width: 90%;
      height: 100%;
      display: flex;
      justify-content: end;

      flex-direction: column;
img{
  width: 30%;
}

      p {
      margin: 0;
        padding: 0;
        margin-top: 0.2em;
     
        font-size: 1.2em;
        font-weight: 400;
      }
      .btnposition {
        width: 100%;
        display: flex;
        justify-content: start;
        
     a{
         text-decoration: none;
        margin-top: 1em;
        background-color: rgba(197, 52, 60, 1);
        width: 20%;
        text-align: center;
        padding: 0.5em;
        border-radius: 2em;
        color: white;
        font-weight: 500;
        font-family: "Lato", sans-serif;
     }
      }
    }
     .socialmedia {
      width: 100%;
      display: flex;

      align-items: center;
      padding: 2.5em 0 2.5em 0;
      gap: 0em;
      hr {
        width: 9%;
        opacity: 1;
        height: 0px;
        color: white;
      }
      .socialmediaicon {
        width: 13%;
        display: flex;
        justify-content: center;
        gap: 0.7em;
        .twitter {
          width: 14%;
        }
        .facebook {
          width: 16%;
        }
        .insta {
          width: 22%;
        }
        .linkedin {
          width: 16%;
        }
      }
      .secondhr {
        width: 78%;
      }
    }
  }
    .who-we-are-section {
      margin-top: 7em;

      .who-we-are-content {
        .who-we-are-img {
          margin-top: 0%;;
          // margin-top: 25%;
          width: 65%;
          img {
            width: 84%;
          }
        }
        .who-we-are-text {
          width: 50%;
          display: flex;
          justify-content:start;
          .titleDescription {
            width: 100%;
            h1 {
              border-bottom: 1.8px solid #c5343c;
              align-items: flex-end;
              font-family: "Italiana", sans-serif;
              font-weight: 500;
              font-size: 2em;
              width: 80%;
            }
            p {
              font-family: "Lato", sans-serif;
              font-size: 1em;
            }
          }
        }
      }
    }
    .opportunities-section {
      margin-top: 8em;

      .opportunities-content {
        padding-top: 3em;
        width: 100%;
        height: 55vh;
        background-color: rgba(0, 0, 0, 1);
        display: flex;
        //   justify-content: center;
        flex-direction: column;
        align-items: center;
        h1 {
          font-size: 3.5em;
          border-bottom: 1.8px solid #c5343c;
        }
      }
      .opportunities-cards {
        margin-top: -13.65em;
        width: 85%;
        display: flex;
        // justify-content: center;
        
        .cards {
          justify-content: space-evenly;
          .cardd {
            width: 100%;
            img {
              margin-top: 8em;
              width: 100%;
            }
            .title {
              h1 {
                // margin-top: 0.3em;
                font-size: 2em;
              }
              ul {
                padding: 1em 2.5em 0em 3em ;
                li {
                  font-size: 0.92em;
                  padding: 1.2em 0 0 0;
                }
              }
            }
          }
        }
      }
    }
    
      .partner-section {
    margin-top: 8em;
    width: 100%;
    // height: 90vh;
    background-image: url("../assests//partner-bgImg.png");
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    padding: 4em 0;
    .partner-container {
      width: 90%;
      display: flex;
      justify-content: center;

      .partner-content {
        width: 100%;
        display: flex;
        justify-content: center;

        .partner-text {
          height: 100%;
          width: 70%;
          color: rgba(255, 255, 255, 1);
          border: 1px solid rgba(255, 255, 255, 1);
          padding: 1em 0 2.5em 1em;
          h1 {
            width: 50%;
            font-family: "Italiana", sans-serif;
            font-size: 2.6em;
            font-weight: 400;
            border-bottom: 1.6px solid #c5343c;
            width: 8em;
            margin-bottom: 0.6em;
          }
          p {
            padding: 0;
            margin: 0;
            width: 84%;
            text-align: justify;
            font-size: 0.87em;
            font-family: "Lato", sans-serif;
            font-weight: 500;
            line-height: 160%;
          }
          .joinusbtnn {
            margin-top: 2em;
            background-color: rgba(197, 52, 60, 1);
            width: 29%;
            text-align: center;
            padding: 0.5em;
            border-radius: 2em;
            color: white;
            font-weight: 500;
            font-family: "Lato", sans-serif;
            // margin-bottom: 2em;
          }
        }
        .partner-img {
          margin-top: 5em;
          width: 60%;
          margin-left: -1em;
          img {
            width: 100%;
            // height: 100%;
          }
        }
      }
    }
  }
    .media {
      .media-content {
        img {
          width: 100%;
        }
        .media-imgtitle {
          h1 {
            font-family: "Italiana", sans-serif;
            font-size: 2.5em;
            border-bottom: 1.8px solid #c5343c;
          }
          .media-img {
            margin-top: 2em;
            display: flex;
            justify-content: center;
            img {
              width: 50%;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1799px){
  .homepage-container {
    width: full;
  
     .about-bg {
      .about-content {
        img{
          width: 26%;
        }

        p {
          // margin-top: 0.3em;

          font-size: 1.9em;
        }
        .joinusbtn {
          font-size: 1.18em;
          padding: 0.5em;
        }
      }
      .socialmedia {
        padding: 4em 0 7em 0;
        hr {
          width: 9%;
          opacity: 1;
          height: 0px;
        }
        .socialmediaicon {
          width: 13%;
          display: flex;
          justify-content: center;
          gap: 0.7em;
          .twitter {
            width: 14%;
          }
          .facebook {
            width: 16%;
          }
          .insta {
            width: 22%;
          }
          .linkedin {
            width: 16%;
          }
        }
        .secondhr {
          width: 78%;
        }
      }
    }
    .who-we-are-section {
      
      .who-we-are-content {
      
        .who-we-are-img {
          img {
          }
        }
        .who-we-are-text {
        
          .titleDescription {
            
            h1 {
              
            }
            p {

            }
          }
        }
      }
    }
    .opportunities-section {
  
      .opportunities-content {
        h1 {
          color: rgba(255, 255, 255, 1);
          font-family: "Italiana", sans-serif;
          font-size: 2.6em;
          font-weight: 400;
          border-bottom: 1.6px solid #c5343c;
          width: 14em;
          text-align: center;
        }
      }
      .opportunities-cards {
        .cardssss{
          display: none;
        }
        .cards {
          // border: 2px solid red;
          .cardd {
            // border: 2px solid red;
            img {
              width: 100%;
            }
            .title {
              h1 {
                
                font-size: 1.8em;
              }
              ul {
            
                li {
                  font-size: 1em;
                  
                }
              }
            }
          }
        }
      }
    }
    .partner-section {
      
      .partner-container {

  
        .partner-content {

  
          .partner-text {
            
            h1 {
              
            }
            p {
        
              font-size: 1.2em;
            }
            .joinusbtnn {
              
            }
          }
          .partner-img {
           
            img {
              
            }
          }
        }
      }
    }
    .media {
     
      .media-content {
       
        img {
         
        }
        .media-imgtitle {
          h1 {
            
          }
          .media-img {
            
            img {
            
            }
          }
        }
      }
    }
  } 
}