@media only screen and (max-width: 576px) {

.hidesection{
  width: 100%;
  margin-left: 0 em !important;

  }
  .slider-section-of-feature {

  margin-top: 0em;
  width: 100%;
  height: 100vh;
    // padding: 3em 3% 5em 3%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.slider-swiper-main-container {
  width: 100%;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  margin: 0 auto;
}

.swiper_container {
  // padding: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  opacity: 1;
}

.swiper_container .swiper-slide {
  padding-top: 2em !important;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%;
height: 100%;
  position: relative;

  // padding: 20px;
  //   border-radius: 30px;
  //   box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  margin: 0 0;
  opacity: 1;
  text-align: center;
  padding: 0em 0em;
  .slide-content-bg {

    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color:transparent;
width: 100%;
    height: 100%;
    .cardd {
          padding-top: 0em !important;
          width: 100%;
          height:100%;
          padding-bottom: 1em;
          background-color: white;
          box-shadow: 0px 0px 18px 4px rgba(0, 0, 0, 0.15) !important;
          border-radius: 0.5em;
          img {
            width: 110% !important;
            margin-top:-1.5em !important;
            margin-left: 0.8em;
          }
          .card-content {
              padding: 0 0.8em 0 0.8em !important;
            h2 {
              font-family: "Italiana", sans-serif;
              font-weight: 600;
              //   margin: 0em 0 1em 0;
              font-size: 0.79em !important;
              height:4.2em !important;
              text-align: start;
              padding: 0 0em 0 0em !important;
            }
            p {
              //    padding: em;
              //   margin-top: 1.2em;
              font-family: "Lato", sans-serif;
              font-weight: 300;
              font-size: 0.7em !important;
              line-height: 120%;
              text-align: justify;
              height:fit-content;
              color: black;
              padding: 0em !important;
            }
            .card-footerrr {
              padding-top: 2em !important;
              //    padding: 1em;
              width: 100%;

              display: flex;
              gap: 0.5em !important; 
              .ashokaimg {
                width: 17% !important;
                img {
                  width: 100%;
                  margin-top: 0em !important;
                  margin-left: 0em;
                }
              }

              .ashokaname {
                width: 100% !important;
                h6 {
                  font-family: "Lato", sans-serif;
                  //   font-weight: 300;
                  font-size: 0.67em !important; 
                  padding: 0 !important;
                  margin: 0 !important;
                  text-align: start !important;
                }
                p {
                  padding: 0 !important;
                  margin: 0 !important;
                  font-family: "Lato", sans-serif;
                  font-weight: 500;
                  font-size: 0.55em !important;
                  height: 0em;
                }
              }
            }
            .readmore {
              padding-top: 0.5em !important;
              display: flex;
              justify-content: end;
              a {
                background-color: rgba(197, 52, 60, 1);
                width: 45% !important;
                text-align: center;
                padding: 0.3em;
                border-radius: 2em;
                color: white;
                font-size: 0.65em;
                font-weight: 500;
                font-family: "Lato", sans-serif;
              }
            }
          }
        }
  }

  .partnerlogo {
    margin-top: -3em;
    // padding-top: 34%;

    width: 5.5em;
  }

}

.swiper-slide-active {
  margin-top: 0.7em !important;

width: 60% !important;
  flex-direction: column;
  //   border: 0.5px solid transparent;
height: fit-content !important;
  text-align: center;

// box-shadow: 0px 9px 10px  4px rgb(146, 145, 145);
z-index: 0 !important;
  .slide-content-bg {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color:transparent;
width: 100% !important;
    height: 100%;
    background-color: transparent !important;
       .cardd {
          padding-top: 0em !important;
          width: 100%;
          height:100%;
          padding-bottom: 1em;
          background-color: white;
          box-shadow: 0px 0px 18px 4px rgba(0, 0, 0, 0.15) !important;
          border-radius: 0.5em;
          img {
            width: 110% !important;
            margin-top:-1.5em !important;
            margin-left: 0.8em;
          }
          .card-content {
              padding: 0 0.8em 0 0.8em !important;
            h2 {
              font-family: "Italiana", sans-serif;
              font-weight: 600;
              //   margin: 0em 0 1em 0;
              font-size: 0.79em !important;
              height: 2.9em !important;
              text-align: start;
              padding: 0 0em 0 0em !important;
            }
            p {
              //    padding: em;
              //   margin-top: 1.2em;
              font-family: "Lato", sans-serif;
              font-weight: 300;
              font-size: 0.7em !important;
              line-height: 120%;
              text-align: justify;
              height:fit-content;
              color: black;
              padding: 0em !important;
            }
            .card-footerrr {
              padding-top: 2em !important;
              //    padding: 1em;
              width: 100%;

              display: flex;
              gap: 0.5em !important; 
              .ashokaimg {
                width: 17% !important;
                img {
                  width: 100%;
                  margin-top: 0em !important;
                  margin-left: 0em;
                }
              }

              .ashokaname {
                width: 100% !important;
                h6 {
                  font-family: "Lato", sans-serif;
                  //   font-weight: 300;
                  font-size: 0.67em !important; 
                  padding: 0 !important;
                  margin: 0 !important;
                  text-align: start !important;
                }
                p {
                  padding: 0 !important;
                  margin: 0 !important;
                  font-family: "Lato", sans-serif;
                  font-weight: 500;
                  font-size: 0.55em !important;
                  height: 0em;
                }
              }
            }
            .readmore {
              padding-top: 0.5em !important;
              display: flex;
              justify-content: end;
              a {
                background-color: rgba(197, 52, 60, 1);
                width: 45% !important;
                text-align: center;
                padding: 0.3em;
                border-radius: 2em;
                color: white;
                font-size: 0.65em;
                font-weight: 500;
                font-family: "Lato", sans-serif;
              }
            }
          }
        }
  }

}
.swiper-3d .swiper-slide-shadow-left {
  background-image: none !important;
  border-radius: 30px;
}
.swiper-3d .swiper-slide-shadow-right {
  background-image: none !important;
}

.swiper-button-prev,
.swiper-button-next {
  width: 30px; /* Adjust the width as needed */
  height: 30px; /* Adjust the height as needed */
  //   background-color: #333; /* Button background color */
  position: absolute;
  top: 50%; /* Adjust the position as needed */
  transform: translateY(-50%); /* Center vertically */
  z-index: 10; /* Ensure the buttons are above the slides */
  border: 2px solid black;
  color: black;
  border-radius: 50%;
  padding: 1.5em;
}
.swiper-button-next:after {
  content: "\2192";
  font-size: 2em;
}
.swiper-button-prev:after {
  content: "\2190";
  font-size: 2em;
}

.swiper-button-prev {
  left: 1em; /* Adjust the position as needed */
}

.swiper-button-next {
  right: 1em; /* Adjust the position as needed */
} } 