.slider-section-of-partner {
  // margin-top: 5em;
  width: 100%;
  height: 100vh;
    // padding: 3em 3% 5em 3%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.slider-swiper-main-container-partner{
  width: 100%;
  // height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  margin: 0 auto;
}

.swiper_containers {
  // padding: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  opacity: 1;
}

.swiper_containers .swiper-slide{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45% !important;

  position: relative;

  // padding: 20px;
  //   border-radius: 30px;
  //   box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  margin: 0 0;
  opacity: 1;
  text-align: center;
  padding: 0em 0em;
  .slide-content-bg-partner {
     
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.9);

    height: 18em;
    img {
      width: 4.7em;
    }
    p {
      color: white;
line-height: 110%;
      padding: 2em 9.4em 2em 9.4em;
    //  padding: 1em 5em 0em 5em !important;
margin: 0;
      font-family: "Lato", sans-serif;
      font-weight: 300;
      font-size: 1em ;
     
      text-align: center;
    }
  }

  .partnerlogo {
    margin-top: -3em;
    // padding-top: 34%;

    width: 5.5em;
  }
  .namedate{
   
visibility: hidden;

      font-family: "Lato", sans-serif;
      font-size: 0.85em !important ;
     
      text-align: center;
      .name{
font-weight: 700 !important;
      }
  }
}

.swiper-slide-active {
  margin-top: 0em !important;
  width: 45% !important;
  flex-direction: column;
  //   border: 0.5px solid transparent;
height: 17em !important;
  text-align: center;

// box-shadow: 0px 9px 10px  4px rgb(146, 145, 145);
z-index: 0 !important;
  .slide-content-bg-partner {
//  width: 31em !important;
 height: 18em;
    background-color: #c5343c !important;
    img {
          width: 4em !important;
    }
    p {
      color: white;

      padding: 1em 6em 0em 6em !important;
margin: 0;
      font-family: "Lato", sans-serif;
      font-weight: 300;
      
   font-size: 0.89em !important;
      text-align: center;
    }
  }
  .partnerlogo {
    margin-top: -3em;
    // padding-top: 34%;

    width: 4.5em !important;
  }
   .namedate{
   
visibility: visible !important;

      font-family: "Lato", sans-serif;
     
      text-align: center;
      .name{
  margin: 0;
        padding: 0;
        font-size: 1.1em !important;
      }
  }
}
.swiper-3d .swiper-slide-shadow-left {
  background-image: none !important;
  border-radius: 30px;
}
.swiper-3d .swiper-slide-shadow-right {
  background-image: none !important;
}

.swiper-button-prev,
.swiper-button-next {
  width: 30px; /* Adjust the width as needed */
  height: 30px; /* Adjust the height as needed */
  //   background-color: #333; /* Button background color */
  position: absolute;
  top: 50%; /* Adjust the position as needed */
  transform: translateY(-50%); /* Center vertically */
  z-index: 10; /* Ensure the buttons are above the slides */
  border: 2px solid black;
  color: black;
  border-radius: 50%;
  padding: 1.5em;
}
.swiper-button-next:after {
  content: "\2192";
  font-size: 2em;
}
.swiper-button-prev:after {
  content: "\2190";
  font-size: 2em;
}

.swiper-button-prev {
  left: 1em; /* Adjust the position as needed */
}

.swiper-button-next {
  right: 1em; /* Adjust the position as needed */
}
.slider-section-of-partnerResponsive{
  display: none;
}

/* Extra Small Devices (Phones) */
@media only screen and (max-width: 576px) {
  .slider-section-of-partner{
    display: none;
  }
.slider-section-of-partnerResponsive {
  display: block;
  // margin-top: 5em;
  width: 100%;
  height: 100vh;
    // padding: 3em 3% 5em 3%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.slider-swiper-main-container-partner{
  width: 100%;
  // height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  margin: 0 auto;
}

.swiper_containers {
  // padding: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  opacity: 1;
}

.swiper_containers .swiper-slide{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45% !important;

  position: relative;

  // padding: 20px;
  //   border-radius: 30px;
  //   box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  margin: 0 0;
  opacity: 1;
  text-align: center;
  padding: 0em 0em;
  .slide-content-bg-partner {
     
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.9);

    height: 15em;
    img {
      width: 3em;
    }
    p {
      color: white;
line-height: 110%;
      padding: 2em 1em 2em 1em;
    //  padding: 1em 5em 0em 5em !important;
margin: 0;
      font-family: "Lato", sans-serif;
      font-weight: 300;
      font-size: 1em ;
     
      text-align: center;
    }
  }

  .partnerlogo {
    margin-top: -3em;
    // padding-top: 34%;

    width: 3.5em;
  }
  .namedate{
   
visibility: hidden;

      font-family: "Lato", sans-serif;
      font-size: 0.85em !important ;
     
      text-align: center;
      .name{
font-weight: 700 !important;
      }
  }
}

.swiper-slide-active {
  margin-top: 0em !important;
  width: 45% !important;
  flex-direction: column;
  //   border: 0.5px solid transparent;
height: 17em !important;
  text-align: center;

// box-shadow: 0px 9px 10px  4px rgb(146, 145, 145);
z-index: 0 !important;
  .slide-content-bg-partner {
 width: 15em !important;
 height: 15em;
    background-color: #c5343c !important;
    img {
          width: 3em !important;
    }
    p {
      color: white;

      padding: 1em 2em 0em 2em !important;
margin: 0;
      font-family: "Lato", sans-serif;
      font-weight: 300;
      
   font-size: 0.85em !important;
      text-align: center;
    }
  }
  .partnerlogo {
    margin-top: -3em;
    // padding-top: 34%;

    width: 3.5em !important;
  }
   .namedate{
   
visibility: visible !important;

      font-family: "Lato", sans-serif;
     
      text-align: center;
      .name{
  margin: 0;
        padding: 0;
        font-size: 0.8em !important;
      }
      p{
                font-size: 0.8em !important;

      }
  }
}
.swiper-3d .swiper-slide-shadow-left {
  background-image: none !important;
  border-radius: 30px;
}
.swiper-3d .swiper-slide-shadow-right {
  background-image: none !important;
}

.swiper-button-prev,
.swiper-button-next {
  width: 30px; /* Adjust the width as needed */
  height: 30px; /* Adjust the height as needed */
  //   background-color: #333; /* Button background color */
  position: absolute;
  top: 50%; /* Adjust the position as needed */
  transform: translateY(-50%); /* Center vertically */
  z-index: 10; /* Ensure the buttons are above the slides */
  border: 2px solid black;
  color: black;
  border-radius: 50%;
  padding: 1.5em;
}
.swiper-button-next:after {
  content: "\2192";
  font-size: 2em;
}
.swiper-button-prev:after {
  content: "\2190";
  font-size: 2em;
}

.swiper-button-prev {
  left: 1em; /* Adjust the position as needed */
}

.swiper-button-next {
  right: 1em; /* Adjust the position as needed */
}
}

/* Small Devices (Portrait Tablets and Small Laptops) */
@media only screen and (min-width: 577px) and (max-width: 768px) {
  .slider-section-of-partner {
 
}

.slider-swiper-main-container-partner{

}

.swiper_containers {
  
}

.swiper_containers .swiper-slide{
 
  .slide-content-bg-partner {
     
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.9);

    height: 14em;
    img {
      width: 3em;
    }
    p {
     font-size: 0.8em;
      padding: 2em 4em 2em 4em;

    }
  }

  .partnerlogo {
   

    width: 4em;
  }
  .namedate{
   
visibility: hidden;

      font-family: "Lato", sans-serif;
      font-size: 0.7em !important ;
     
      text-align: center;
      .name{
font-weight: 700 !important;
      }
  }
}

.swiper-slide-active {

height: 14em !important;

  .slide-content-bg-partner {
//  width: 31em !important;
 height: 14em;
    background-color: #c5343c !important;
    img {
          width: 3em !important;
    }
    p {
      color: white;

      padding: 1em 4em 0em 4em !important;
margin: 0;
      font-family: "Lato", sans-serif;
      font-weight: 300;
      
   font-size: 0.8em !important;
      text-align: center;
    }
  }
  .partnerlogo {
    margin-top: -3em;
    // padding-top: 34%;

    width: 4.5em !important;
  }
   .namedate{
   
visibility: visible !important;

      font-family: "Lato", sans-serif;
     
      text-align: center;
      .name{
  margin: 0;
        padding: 0;
        font-size: 1.1em !important;
      }
  }
}
.swiper-3d .swiper-slide-shadow-left {
  background-image: none !important;
  border-radius: 30px;
}
.swiper-3d .swiper-slide-shadow-right {
  background-image: none !important;
}

.swiper-button-prev,
.swiper-button-next {
  width: 30px; /* Adjust the width as needed */
  height: 30px; /* Adjust the height as needed */
  //   background-color: #333; /* Button background color */
  position: absolute;
  top: 50%; /* Adjust the position as needed */
  transform: translateY(-50%); /* Center vertically */
  z-index: 10; /* Ensure the buttons are above the slides */
  border: 2px solid black;
  color: black;
  border-radius: 50%;
  padding: 1.5em;
}
.swiper-button-next:after {
  content: "\2192";
  font-size: 2em;
}
.swiper-button-prev:after {
  content: "\2190";
  font-size: 2em;
}

.swiper-button-prev {
  left: 1em; /* Adjust the position as needed */
}

.swiper-button-next {
  right: 1em; /* Adjust the position as needed */
}
.slider-section-of-partnerResponsive{
  display: none;
}
}

/* Medium Devices (Landscape Tablets and Larger Laptops) */
@media only screen and (min-width: 769px) and (max-width: 992px) {
  /* Your CSS rules for medium devices here */
}

/* Large Devices (Desktops) */
@media only screen and (min-width: 993px) and (max-width: 1200px) {
  /* Your CSS rules for large devices here */
}

/* Extra Large Devices (Large Desktops) */
@media only screen and (min-width: 1201px) and (max-width: 1440px) {
  /* Your CSS rules for extra large devices here */
}

/* XXL Devices (Extra Large Desktops) */
@media only screen and (min-width: 1441px) and (max-width: 1799px) {
 .slider-section-of-languages {
 
}

.slider-swiper-main-container {

}

.swiper_container {
  
}

.swiper_container .swiper-slide {

  .slide-content-bg {
   

    height: 18em;
    img {
      width: 4.7em;
    }
    p {
    
      font-size: 1.25em ;
     
      text-align: center;
    }
  }

  .partnerlogo {
   
  }
  .namedate{
   
visibility: hidden;

      font-family: "Lato", sans-serif;
      font-size: 0.85em !important ;
     
      text-align: center;
      .name{
font-weight: 700 !important;
      }
  }
}

.swiper-slide-active {

height: 19em !important;
 
  .slide-content-bg {
 
    background-color: #c5343c !important;
    img {
          width: 4em !important;
    }
    p {
     
      
   font-size: 1.12em !important;
     
    }
  }
  .partnerlogo {
    margin-top: -3em;
    // padding-top: 34%;

    width: 4.5em !important;
  }
   .namedate{
   
visibility: visible !important;

      font-family: "Lato", sans-serif;
     
     
      text-align: center;
      .name{
        margin: 0;
        padding: 0;
        font-size: 1.2em !important;
font-weight: 700 !important;
      }
  }
}
.swiper-3d .swiper-slide-shadow-left {
  background-image: none !important;
  border-radius: 30px;
}
.swiper-3d .swiper-slide-shadow-right {
  background-image: none !important;
}

.swiper-button-prev,
.swiper-button-next {
  width: 30px; /* Adjust the width as needed */
  height: 30px; /* Adjust the height as needed */
  //   background-color: #333; /* Button background color */
  position: absolute;
  top: 50%; /* Adjust the position as needed */
  transform: translateY(-50%); /* Center vertically */
  z-index: 10; /* Ensure the buttons are above the slides */
  border: 2px solid black;
  color: black;
  border-radius: 50%;
  padding: 1.5em;
}
.swiper-button-next:after {
  content: "\2192";
  font-size: 2em;
}
.swiper-button-prev:after {
  content: "\2190";
  font-size: 2em;
}

.swiper-button-prev {
  left: 1em; /* Adjust the position as needed */
}

.swiper-button-next {
  right: 1em; /* Adjust the position as needed */
}
}

/* Huge Devices (4K Monitors and Above) */
@media only screen and (min-width: 1800px) {
}
